import React, { useState, useEffect } from 'react'
import objectPath from 'object-path'
import { Card, CardBody, CardHeader, FormGroup, FormSelect, Button, ButtonGroup } from '@ui'
import rest from '@rest'
import GalleryBlock from './GalleryBlock'
import AgegroupsBlock from './AgegroupsBlock'
import DatasourcesBlock from './DatasourcesBlock'

const mapFields = (arr, data, splitted) => {
    return arr.reduce((acc, f) => {
        if(!splitted) {
            acc[f.path] = objectPath.get(data, f.path)
        } else {
            const path = f.path.replace('botConfig.', '')
            for(let i=0; i < data.agegroups.length; i++) {
                acc[`agegroups.${i}.${path}`] = objectPath.get(data, `agegroups.${i}.${path}`)
            }
        }
        return acc
    }, {})
}

const Control = (props) => {
    const { data, value, patch } = props
    switch (data.type) {
        case 'select':
            return  <FormSelect value={value} onChange={e => patch(e.target.value)}>
                        {data.options.map(o => (
                            <option value={o.value}>{o.label}</option>
                        ))}
                    </FormSelect>
            break
        case 'agegroups':
            return <AgegroupsBlock {...props} />
            break
        case 'gallery':
            return <GalleryBlock {...props} />
            break
        case 'datasources':
            return <DatasourcesBlock {...props} />
            break
    }
}

const Block = ({ config, data, onUpdatedItem, splitted }) => {
    const [init, setInit] = useState({})
    const [form, setForm] = useState({})
    const [saving, setSaving] = useState(false)
    const [ageGroupIdx, setAgeGroupIdx] = useState(0)

    useEffect(() => {
        const form = mapFields(config.fields, data, splitted)
        setInit(form)
        setForm(form)
    }, [data._id])

    const saveBlock = () => {
        setSaving(true)
        rest('put', config.entity, data._id, form)
            .then(upd => {
                console.log(upd)
                onUpdatedItem(upd)
                setSaving(false)
                setInit({...form})
            })
    }

    const unsaved = JSON.stringify(form) !== JSON.stringify(init)

    return  <Card className={config.cardSuffix}>
                <CardHeader>
                    {config.title}
                    {splitted ? (
                        <div style={{paddingLeft: '1.875rem', marginTop: '1em'}}>
                            <ButtonGroup size='sm'>
                                {data.agegroups.map((ag, idx) => (
                                    <Button onClick={() => setAgeGroupIdx(idx)} theme={idx === ageGroupIdx ? 'primary' : 'light'}>{ag.year}</Button>
                                ))}
                            </ButtonGroup>
                        </div>
                    ) : null}
                    {unsaved ? <Button pill disabled={saving} onClick={() => saveBlock()} style={{position: 'absolute', top: 11}}>Сохранить</Button> : null}
                </CardHeader>
                    <CardBody className='compact'>
                        {config.fields.map(f => (
                            <FormGroup>
                                <label>{f.label}</label>
                                <Control
                                    data={f}
                                    value={f.path === 'root' ? {...data} : splitted ? form[`agegroups.${ageGroupIdx}.${f.path.replace('botConfig.', '')}`] : form[f.path]}
                                    patch={v => setForm({...form, [splitted ? `agegroups.${ageGroupIdx}.${f.path.replace('botConfig.', '')}` : f.path]: v})}
                                />
                            </FormGroup>
                        ))}
                    </CardBody>
            </Card>

}

export default Block
