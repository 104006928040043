import React, { useState } from 'react'
import { Button } from '@ui'
import rest from '@rest'

const base = 'https://amateum.fra1.cdn.digitaloceanspaces.com/presets/'
const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
  })
}

const GalleryBlock = ({ value, data, patch }) => {
    const [uploading, setUploading] = useState(false)

    const handleAttach = async e => {
        setUploading(true)
        const reader = new FileReader()
        reader.onload = () => {
            const filename = uuidv4()
            const ext = e.target.files[0].type.includes('png') ? 'png' : 'jpg'
            rest('put', 'uploader', null, {target: 'presets', filename: filename, ext: ext, data: reader.result})
                .then(upl => {
                    setUploading(false)
                    console.log('Uploaded', `${base}${filename}.${ext}`)
                    patch([`${base}${filename}.${ext}`].concat(value))
                })
        }
        reader.readAsDataURL(e.target.files[0])
    }

    const unlinkFile = _f => {
        patch(value.filter(f => f !== _f))
    }

    return  <div class='gallery grid g3'>
                <Button disabled={uploading} className='gallery-grid_item' outline>
                    <i className='material-icons'>{uploading ? 'hourglass_bottom' : 'add_circle_outline'}</i>
                    {!uploading ? <input type='file' onChange={handleAttach} /> : null}
                </Button>
                {value ? value.map(f => (
                    <div className='gallery-grid_item' style={{backgroundImage: `url(${f})`}}>
                        <Button onClick={() => unlinkFile(f)} size='sm' theme='danger'><i className='material-icons'>delete_outline</i></Button>
                    </div>
                )) : null}
            </div>
}

export default GalleryBlock
