import React, { useState } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from '@ui'

const ClubCard = ({ data, setItem, selected }) => {
    return  <Card className={`inter${selected ? ' selected' : ''}`} onClick={() => setItem({...data})}>
                <div className='card-emb' style={{backgroundImage: `url(${data.emblem})`}}></div>
                <CardHeader>{data.from ? data.from.city : null}</CardHeader>
                <CardBody className='compact'>
                    <CardTitle>{data.name}</CardTitle>
                </CardBody>
            </Card>
}

export default ClubCard
