import React, { useState, useEffect } from 'react'
import { Button, FormGroup, FormInput, InputGroup, InputGroupAddon, FormCheckbox, Badge, FormSelect } from '@ui'
import './style.css'

const EntityButton = ({ init, onSubmit, cta, theme }) => {
    const [clicked, setClicked] = useState(false)
    const [form, setForm] = useState({...init})
    const [progress, setProgress] = useState(false)

    useEffect(() => {
        if(progress) {
            setTimeout(() => {
                setProgress(false)
                setClicked(false)
                setForm({...init})
            }, 2000)
        }
    }, [progress])

    const patch = (k, v) => {
        setForm({
            ...form,
            [k]: {
                ...form[k],
                value: v
            }
        })
    }

    let addon

    return  <div className={'entity-btn'+(clicked ? ' clicked' : '')}>
                <Button theme={theme} onClick={() => setClicked(true)}>{cta}</Button>
                <FormGroup
                    inline={true}
                    disabled={progress}
                >
                    <InputGroup>
                        {Object.keys(form).map(fld => {
                            switch(form[fld].type) {
                                case 'text':
                                    return <FormInput placeholder={form[fld].placeholder} value={form[fld].value} onChange={e => patch(fld, e.target.value)}/>
                                    break
                                case 'checkbox':
                                    addon = <Badge theme='light'><FormCheckbox small checked={form[fld].value} onChange={e => patch(fld, !form[fld].value)}>{form[fld].label}</FormCheckbox></Badge>
                                    return null
                                    break
                                case 'select':
                                    addon = <FormSelect onChange={e => patch(fld, e.target.value)}>
                                                {form[fld].options.map(o => (
                                                    <option value={o.value}>{o.label}</option>
                                                ))}
                                            </FormSelect>
                                default:
                                    return null
                            }
                        })}
                        <InputGroupAddon type='append'>
                            {addon}
                            <Button theme='success' onClick={() => {setProgress(true); onSubmit(form)}}><span className="material-icons">done</span></Button>
                            <Button theme='light' onClick={() => setClicked(false)}>Отмена</Button>
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>
            </div>
}

export default EntityButton
