import React, { useState, useEffect } from 'react';
import { Container,
    Card,
    CardBody,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormGroup,
    FormInput,
    InputGroup,
    FormSelect,
    FormTextarea,
    Button,
    ButtonGroup
} from "shards-react";
import BotUser from './BotUser.js';
import AddUserForm from './AddUserForm.js';
//import AddLeagueRoleForm from './AddLeagueRoleForm.js';
const moment = require('moment');
moment.locale('ru');

const rest = require('../Workspace/rest');
// const testUsers = [
//     {
//         _id: 'tid.15',
//         tid: 15,
//         first_name: 'Mikhail',
//         last_name: 'Petrov',
//         email: null,
//         roles: [],
//         broadcastDefaults: null
//     },
//     {
//         _id: 'tid.156',
//         tid: 156,
//         first_name: '156Mikhail',
//         last_name: '156Petrov',
//         email: null,
//         roles: [],
//         broadcastDefaults: null
//     }
// ]
/*
0: {clubId: '6114ea7d5084ef4dd8dab8e6', seasonId: '6114e8ab5084ef4dd8dab8e2', role: 'admin'}
1: {clubId: '61a9edc643890b0f6422c012', role: 'admin', dataSourceId: '61aa320743890b0f6422c014'}
2: {clubId: '6023a2bdaeea140f6131e7df', role: 'admin', seasonId: '6023a27aaeea140f6131e7de'}
*/

const BotUsers = () => {
    const [users, setUsers] = useState([]);
    const [filtUsers, setFiltUsers] = useState([]);
    const [visibleForm, setVisibleUserForm] = useState(null);
    const [botUser, setBotUser] = useState(null);
    const [term, setTerm] = useState('');

    useEffect(() => {
        setVisibleUserForm(false)
    }, []);

    useEffect(() => {
        if (!visibleForm) {
            rest('get','botusers')
                .then(items => {
                    setUsers(items.filter(item => !item.archived ));
                    setFiltUsers(items.filter(item => !item.archived ));
                })
                .catch(err => console.log(err));
        }
    }, [visibleForm]);

    useEffect(() => {
        if (term.length > 2) {
            setFiltUsers(users.filter(item => item.first_name.toLowerCase().includes(term) || item.last_name.toLowerCase().includes(term)))
        } else {
            setFiltUsers(users)
        }
    }, [term]);

    const delUser = id => {
        rest('put', 'botusers', id, {'archived': moment().format('YY-MM-DD')})
            .then(del => {
                const filtred = users.filter(user => user._id.toString() !== id);
                const indx = users.findIndex(user => user._id.toString() === id);
                users[indx].archived = moment().format('YY-MM-DD')
                setUsers(filtred);
                const filt = filtUsers.filter(user => user._id.toString() !== id);
                const ind = filtUsers.findIndex(user => user._id.toString() === id);
                filtUsers[ind].archived = moment().format('YY-MM-DD')
                setFiltUsers(filt);
            })
            .catch(e => console.log(e))
    }
    const handleField = (evt) => {
        let key = evt.target.name;
        console.log('term', key, evt.target.value);
        setTerm(evt.target.value.toLowerCase());
    }

    const addUser = (user=null) => {
        setBotUser(user);
        setVisibleUserForm(true);
    }
    const toBotUsers = () => {
        setVisibleUserForm(false);
        setTerm('');
    }
    return (
        visibleForm ?
            <AddUserForm
                user={botUser}
                toBotUsers={toBotUsers}
            />
        :
            <Container>
                <InputGroup seamless className="ml-3">
                    <FormInput
                        className="navbar-search"
                        placeholder="write name"
                        id = 'term'
                        name = 'term'
                        value={term}
                        onChange={handleField}
                        autoComplete='off'
                        autoFocus
                    />
                </InputGroup>
                <div style={{paddingLeft: 10, maxHeight:600, overflowY:'scroll', overflowX:'hidden', marginTop: 20, marginBottom: 20}}>
                {filtUsers.map(user => <BotUser
                        key={user._id}
                        user={user}
                        delUser={() => delUser(user._id)}
                        changeUser={() => addUser(user)}
                    />
                )}
                </div>
                <Row className="d-flex">
                    <Button
                        pill
                        active
                        onClick = {() => addUser()}
                        theme="success"
                        size="lg"
                    ><i className="material-icons">save</i> Add user </Button>
                </Row>
            </Container>
    )
};

export default BotUsers;
