import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Container, Row, Col } from 'shards-react'
import { ClubCard, ClubItem } from '@components/clubs'
import rest from '@rest'

const Clubs = () => {
    const [list, setList] = useState([])
    const history = useHistory()
    const { itemId } = useParams()

    useEffect(() => {
        rest('get', 'clubs?filter[botConfig]=exists')
            .then(list => {
                setList(list)
            })
    }, [])

    const item = itemId ? list.find(i => i._id === itemId) || null : null

    return  <Container className='content-container'>
                <Row>
                    <Col>
                        {!list.length ? (
                            <div>Загрузка
                            </div>
                        ) : (
                            <div className='grid g2'>
                                {list.map(club => (
                                    <ClubCard
                                        data={club}
                                        setItem={() => history.push(`/clubs/${club._id}`)}
                                        selected={itemId && itemId === club._id}
                                    />
                                ))}
                            </div>
                        )}
                    </Col>
                    <Col>
                        {!item ? (
                            <div>клуб не выбран</div>
                        ) : (
                            <ClubItem
                                data={item}
                                onUpdatedItem={item => setList(list.map(c => c._id === item._id ? item : c))}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
}

export default Clubs
