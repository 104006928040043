const widgets = [
    [
        {
            type: 'areaChart',
            title: 'Матчи (неделя)',
            endpoint: 'areaChart',
            width: 1,
            minHeight: '140px',
            params: {
                collection: 'matches',
                required: ['draft.matchday'],
                project: ['date'],
                filters: {
                    period: 'week',
                    periodKey: 'date'
                }
            },
            backgroundColor: "rgba(0, 184, 216, 0.1)",
            borderColor: "rgb(0, 184, 216)",
        },
        {
            type: 'areaChart',
            title: 'Матчи (месяц)',
            endpoint: 'areaChart',
            width: 1,
            params: {
                collection: 'matches',
                required: ['draft.matchday'],
                project: ['date'],
                filters: {
                    period: 'month',
                    periodKey: 'date'
                }
            },
            backgroundColor: "rgba(23,198,113,0.1)",
            borderColor: "rgb(23,198,113)",
        },
        {
            type: 'areaChart',
            title: 'Выручка Матчи',
            endpoint: 'areaChart',
            width: 1,
            params: {
                collection: 'matches',
                required: ['draft.matchday'],
                project: ['date', 'price'],
                filters: {
                    period: 'month',
                    periodKey: 'date'
                },
                groupBy: 'price'
            },
            backgroundColor: "rgba(255,180,0,0.1)",
            borderColor: "rgb(255,180,0)",
        },
        {
            type: 'areaChart',
            title: 'бродкасты (месяц)',
            endpoint: 'areaChart',
            width: 1,
            backgroundColor: "rgba(255,65,105,0.1)",
            borderColor: "rgb(255,65,105)",
            params: {
                collection: 'broadcasts',
                required: ['createdAt'],
                project: [],
                filters: {
                    period: 'month',
                    periodKey: 'createdAt',
                    syncSeconds: {min: 600}
                }
            },
        },
        {
            type: 'areaChart',
            title: 'Выручка Бродкасты',
            endpoint: 'areaChart',
            width: 1,
            backgroundColor: "rgb(0,123,255,0.1)",
            borderColor: "rgb(0,123,255)",
            params: {
                collection: 'broadcasts',
                required: ['createdAt'],
                project: ['price'],
                groupBy: 'price',
                filters: {
                    period: 'month',
                    periodKey: 'createdAt',
                    syncSeconds: {min: 600}
                }
            },
        },
    ],
    [
        {
            type: 'mixedChart',
            title: 'Статусы матчей',
            endpoint: 'mixedChart',
            width: 2,
            minHeight: '416px',
            params: {
                collection: 'matches',
                required: ['date'],
                project: ['draft.matchday','leagueId'],
                filters: {
                    period: 'week',
                    periodKey: 'date',
                    count: 15,
                    entryId: null,
                    filterField: 'leagueId'
                }
            },
            counts: [5, 15, 30, 45],
            chartData: {
              labels: [],/*Array.from(new Array(30), (_, i) => (i === 0 ? 1 : i)),*/
              datasets: [
                {
                  label: "Обработаны",
                  fill: "start",
                  data: [],
                  backgroundColor: "rgba(0,123,255,0.1)",
                  borderColor: "rgba(0,123,255,1)",
                  pointBackgroundColor: "#ffffff",
                  pointHoverBackgroundColor: "rgb(0,123,255)",
                  borderWidth: 1.5,
                  pointRadius: 0,
                  pointHoverRadius: 3
                },
                {
                  label: "Не поступали",
                  fill: "start",
                  data: [],
                  backgroundColor: "rgba(255,65,105,0.1)",
                  borderColor: "rgba(255,65,105,1)",
                  pointBackgroundColor: "#ffffff",
                  pointHoverBackgroundColor: "rgba(255,65,105,1)",
                  borderDash: [3, 3],
                  borderWidth: 1,
                  pointRadius: 0,
                  pointHoverRadius: 2,
                  pointBorderColor: "rgba(255,65,105,1)"
                }
              ]
            }
        },
        {
            type: 'pieChart',
            title: 'Повторная обработка',
            endpoint: 'pieChart',
            width: 1
        }
    ],
    [
        {
            type: 'persons',
            title: 'Модераторы',
            endpoint: 'persons',
            width: 2,
            minHeight: '520px',
            params: {
                collection: 'matches',
                required: ['date','moderator'],
                project: [],
                filters: {
                    periods: [{prevMonth: 'Прошлый месяц'}, {month: 'Месяц'}, {prevWeek: 'Прошлая неделя'}, {week: 'Неделя'}, {day: 'Сегодня'} ],
                    periodKey: 'date',
                    groupBy: 'moderator',
                    limit: 3,
                    lookup: {
                        collection: 'staffs',
                        attribution: ['moderator', 'tid']
                    }
                }
            }
        },
        {
            type: 'topList',
            title: 'ТОР по бродкастам',
            endpoint: 'topList',
            width: 1,
            params: {
                collection: 'broadcasts',
                required: ['createdAt', 'tid'],
                project: [],
                filters: {
                    period: 'month',
                    periodKey: 'createdAt',
                    groupBy: 'tid',
                    lookup: {
                        collection: 'botusers',
                        attribution: ['tid', 'tid']
                    },
                    syncSeconds: {min: 600}
                }
            },
            selects: [{prevMonth: 'Прошлый месяц'}, {month: 'Месяц'}, {prevWeek: 'Прошлая неделя'}, {week: 'Неделя'}, {[`3month`]: '3 Месяца'} ],
        },
        {
            type: 'topList',
            title: 'ТОР по матчам',
            endpoint: 'topList',
            width: 1,
            params: {
                collection: 'matches',
                required: ['leagueId','draft.matchday'],
                project: ['date'],
                filters: {
                    period: 'month',
                    periodKey: 'date',
                    groupBy: 'leagueId',
                    lookup: {
                        collection: 'leagues',
                        attribution: ['leagueId', '_id']
                    },
                }
            },
            selects: [{prevMonth: 'Прошлый месяц'}, {month: 'Месяц'}, {[`3month`]: '3 Месяца'}, {prevWeek: 'Прошлая неделя'}, {week: 'Неделя'}],
        }
    ]
]

export default widgets
