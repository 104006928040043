import React, { useState, useEffect } from 'react';
import { Container,
    Card,
    CardBody,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormGroup,
    FormInput,
    FormSelect,
    FormTextarea,
    Button,
    ButtonGroup
} from "shards-react";

const rest = require('../Workspace/rest');

const Role = ({role, delRole, changeRole}) => {
    
    const [userRole, setUserRole] = useState(role);

    return (
        userRole ?
        <Row>
            <Col>
                <ListGroup flush style={{ width: "100%" }}>
                    <ListGroupItem className="d-flex px-3 border-0">
                         <span>{userRole.name}</span>
                        <ButtonGroup size="sm">
                            <Button
                                pill
                                active
                                onClick = {delRole}
                                theme="danger"
                            ><i className="material-icons">delete</i> Delete </Button>
                            {changeRole ? <Button
                                pill
                                active
                                onClick = {changeRole}
                                theme="secondary"
                            > <i className="material-icons">edit</i> Change </Button>
                            : null}
                        </ButtonGroup>
                    </ListGroupItem>
                </ListGroup>
            </Col>
        </Row>
        : null
    )

}

export default Role;
