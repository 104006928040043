import React, { useState } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from '@ui'

const PlayerCard = ({ data, selectData, selected, warning }) => {
    // <CardBody className='compact'>
    //     <CardTitle></CardTitle>
    // </CardBody>

    return  data ? <Card className={`inter${selected ?' selectedcard' : ''} ${warning ? 'warningcard' : ''}`} onClick={() => selectData(data)} style={{maxHeight: "80px"}}>
                <div className='card-emb' style={{backgroundImage: `url(${data.avatarUrl || data.image})`}}></div>
                <CardHeader>{data.name}</CardHeader>
                <p style={{textAlign: "right"}}>{data.moderated ? data.moderated : null}</p>
            </Card> : null
}

export default PlayerCard
