import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ListGroup,
  ListGroupItem,
  FormSelect
} from "shards-react";

const TopList = (props) => {
    const {data, title, selects, minHeight, onHandle, date} = props;

    return data ?
    <Card small style={{minHeight}}>
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
        <div className="block-handle" />
      </CardHeader>
    <CardBody className="p-0">
        <ListGroup small flush className="list-group-small">
        {data.map(item => {

            return <ListGroupItem key={item._id} className="d-flex px-3" style={{border: 'none'}}>
                {/**/}
                <span className="text-semibold text-fiord-blue">{item.name}</span>
                <span className="ml-auto text-right text-semibold text-reagent-gray">
                {item.count}
                </span>
            </ListGroupItem>

        })}
        </ListGroup>
    </CardBody>

<CardFooter className="border-top">

      <FormSelect
        size="sm"
        value={date}
        style={{ maxWidth: "130px" }}
        onChange={onHandle}
      >
        {selects ? selects.map(item => {
            let key = Object.keys(item)[0];
            return <option key={key} value={key}>{item[key]}</option>
        }) : <option value={'month'}>Месяц</option>}
      </FormSelect>

</CardFooter>

    </Card>
    : null;
}

export default TopList;
