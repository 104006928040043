import React, { useState, useEffect } from 'react';
import { Container,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormGroup,
    FormInput,
    InputGroup,
    FormSelect,
    FormTextarea,
    Button,
    ButtonGroup
} from "shards-react";
// import BotUser from './BotUser.js';
//import AddUserForm from './AddUserForm.js';
//import AddLeagueRoleForm from './AddLeagueRoleForm.js';
const moment = require('moment');
moment.locale('ru');

const rest = require('../Workspace/rest');

const BroadcastCard = ({data}) => {
    if (data) {
        return (
            <Card style={{ maxWidth: "600px" }}>
                <CardBody style={{ display: 'flex', flexDirection:'column'}}>
                    <p>{data.homeFullname} vs {data.awayFullname}</p>
                    <p>Создана: {moment(data.createdAt).format('DD-MM-YYYY')}. state: {data.state}</p>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                        <p>результат: {data.homeScore} : {data.awayScore}</p>
                        <p>sync: {data.syncSeconds}</p>
                        <p>timer: {data.timer ? 'Да' : 'Нет'}</p>
                        <p>custom: {data.leagueId ? 'Да' : 'Нет'}</p>
                    </div>
                    <p>botUser: {data.tid} {data.botuser.first_name} {data.botuser.last_name}</p>
                </CardBody>
            </Card>
        )
    } else {
        return null
    }
};

export default BroadcastCard;
