const axios = require('axios')
const { REST } = require('../../env')

module.exports = (method, collection, id=null, body) => {
    return new Promise((resolve, reject) => {
        axios[method](`${REST}${collection}${id ? '/'+id : ''}`, body || {})
            .then(resp => {
                resolve(resp.data)
            })
    })
}
