import rest from '@rest'

const utils = {}

utils.base = 'https://amateum.fra1.cdn.digitaloceanspaces.com/';

utils.uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
  })
}

utils.uplImgToDO = async ({target, benzin, trim, defImgUrl, filename, ext, data}) => {
    //target: 'presets', filename: filename, ext: ext, data: reader.result, benzin: true
    let imgUrl = rest('put', 'uploader', null, {target, benzin, defImgUrl, filename, ext, data, trim})
        .then(upl => upl.url)
        .catch(e => {
            console.log(e);
            return 'error'
        });
    return imgUrl ? imgUrl : '';
}

export default utils
