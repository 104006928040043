import React, { useState } from 'react'
import './style.css'

const NonIdealState = ({ icon, label }) => {
    return  <div className='non-ideal-state'>
                <div className='material-icons'>{icon || 'add_moderator'}</div>
                <span>{label}</span>
            </div>
}

export default NonIdealState
