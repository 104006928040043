import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormRadio, FormInput, Dropdown, DropdownItem, DropdownMenu, Button, InputGroupAddon, InputGroupText,InputGroup } from 'shards-react';
import BroadcastCard  from './BroadcastCard';
import rest from '@rest';
const moment = require('moment');
moment.locale('ru');
const Broadcasts = () => {

    const [list, setList] = useState([]);
    const [states, setStates] = useState(['all']);
    const [state, setState] = useState('all');
    const [filtList, setFiltList] = useState([]);
    // const [term, setTerm] = useState('');
    // const [start, setStart] = useState('');
    // const [end, setEnd] = useState('');
    const [input, setInput] = useState({
        term: '',
        start: moment().add(-7, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD')
    })
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //rest('get', 'clubs?filter[botConfig]=exists')
        rest('get', 'broadcasts')
            .then(broadcasts => {
                setList(broadcasts);
                setFiltList(broadcasts.filter(item => (item.createdAt >= toTimeStamp(input.start) && item.createdAt <= toTimeStamp(input.end))));
                const st = [];
                for (let i=0; i<broadcasts.length; i++) {
                    if (st.includes(broadcasts[i].state)) {
                        continue;
                    } else {
                        st.push(broadcasts[i].state);
                    }
                }
                setStates(states.concat(st))
            });
    }, [])

    useEffect(() => {
        if (user && user.tid) {
            if (input.end && input.start) {
                if (state !== 'all') {
                    setFiltList(list.filter(item => (item.state === state && item.tid === user.tid) && (item.createdAt >= toTimeStamp(input.start) && item.createdAt <= toTimeStamp(moment(input.end).add(1, 'days').format()) )));
                } else {
                    setFiltList(list.filter(item => item.tid === user.tid && (item.createdAt >= toTimeStamp(input.start) && item.createdAt <= toTimeStamp(moment(input.end).add(1, 'days').format()))))
                }
            } else {
                if (state !== 'all') {
                    setFiltList(list.filter(item => item.state === state && item.tid === user.tid));
                } else {
                    setFiltList(list.filter(item => item.tid === user.tid));
                }
            }
        } else {
            if (input.end && input.start) {
                /*console.log(input.start, toTimeStamp(moment(input.start).format()), moment(input.start).format())
                console.log(moment(input.end).add(1, 'days').format('YYYY-MM-DD'), toTimeStamp(moment(input.end).add(1, 'days').format()), moment(input.end).add(1, 'days').format())
                console.log(moment(1639785324037).format('YYYY-MM-DD'), 1639785324037, moment(1639785324037).format());
                console.log('1639778400000 <= 1639785324037', 1639778400000 <= 1639785324037, '1639785324037 <= 1639778400000', 1639785324037 <= 1639778400000)
                console.log(`${toTimeStamp(input.start)}  <= 1639785324037 && 1639785324037 <= ${toTimeStamp(moment(input.end).add(1, 'days').format())}`)
                console.log(toTimeStamp(input.start) <= 1639785324037 && 1639785324037 <= toTimeStamp(moment(input.end).add(1, 'days').format()))
*/
                if (state !== 'all') {
                    setFiltList(list.filter(item => item.state === state && (item.createdAt >= toTimeStamp(input.start) && item.createdAt <= toTimeStamp(moment(input.end).add(1, 'days').format()))));
                } else {
                    setFiltList(list.filter (item => (toTimeStamp(input.start) <= item.createdAt && item.createdAt <= toTimeStamp(moment(input.end).add(1, 'days').format()))));
                }
            } else {
                if (state !== 'all') {
                    setFiltList(list.filter(item => item.state === state));
                } else {
                    setFiltList(list);
                }
            }
        }
    }, [state, user, input.end, input.start]);

    useEffect(() => {
        // setTid('');
        // setUsers([]);
        if (input.term.length > 2) {
            setUser(null);
            setUsers([]);
            setLoading(true);
            //rest('get', 'botusers', `?suggest[last_name, first_name]=${term}`)
            rest('get', 'botusers')
                .then(items => {
                    setUsers(items.filter(item => item.first_name.toLowerCase().includes(input.term.toLowerCase()) || item.last_name.toLowerCase().includes(input.term.toLowerCase())));
                    setLoading(false);
                })
        }
    }, [input.term]);

    const handleField = evt => {
        const key = evt.target.name;
        setInput({
            ...input,
            [key]: evt.target.value
        });
    }

    const getUser = (user) => {
        //setTerm('');
        setInput({
            ...input,
            term: ''
        });
        setUser(user);
        setUsers([]);
    }
    const clear = () => {
        //setTerm('');
        setInput({
            start: moment().add(-7, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
            term: ''
        });
        setUser(null);
        setUsers([]);
    }
    const toTimeStamp = str => {
        const date = new Date(str);
        return date.getTime();
    }
    return  <Container className='content-container'>
                <Row>
                    <Col>
                        {states.map(item => (
                            <FormRadio
                                key={item}
                                inline
                                name="state"
                                checked={state === item}
                                onChange={() => setState(item)}
                            >{item}</FormRadio>
                        ))}
                    </Col>
                </Row>
                <Row style={{maxWidth:'51%'}}>
                    <Col>
                        <FormInput
                            placeholder="write user name"
                            name = 'term'
                            value={input.term}
                            onChange={handleField}
                            autoComplete='off'
                            type='search'
                        />
                    </Col>
                </Row>
                <Row style={{maxWidth:'51%', display: (users.length && !user) ? 'block': 'none'}}>
                    <Col>
                        <Dropdown open={!user && users.length }>
                        <DropdownMenu>
                            {users.map(item => {
                                return (
                                    <DropdownItem
                                        key={item._id}
                                        onClick={() => getUser(item)}
                                    >{item.first_name} {item.last_name}</DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row style={{maxWidth:'51%', display: (user) ? 'block': 'none'}}>
                    <Col>
                        {user ? <div>Выбран:  {user.first_name} {user.last_name}
                        <Button
                            onClick = {() => clear()}
                            theme="danger"
                            outline
                        >Убрать</Button></div> : null}

                    </Col>
                </Row>
                <Row style={{maxWidth:'51%'}}>
                    <Col>
                        <InputGroup>
                            <InputGroupAddon type="prepend">
                                <InputGroupText>С</InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                                name = 'start'
                                value={input.start}
                                onChange={handleField}
                                autoComplete='off'
                                type='date'
                            />
                            <InputGroupAddon type="prepend">
                                <InputGroupText>По</InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                                name = 'end'
                                value={input.end}
                                onChange={handleField}
                                autoComplete='off'
                                type='date'
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {!filtList.length ? (loading ? <div>Загрузка</div> : <div>нет данных</div>)
                         : (
                            <div className='grid g1' style={{maxHeight:700, overflowY:'scroll', overflowX:'hidden', marginTop: 20, paddingLeft: 30,  paddingRight: 30}}>
                                {filtList.map(item => (
                                    <BroadcastCard
                                        key={item._id}
                                        data={item}
                                    />
                                ))}
                            </div>
                        )}
                    </Col>
                    <Col>
                        {states.map(state => {
                            if (state === 'all') {
                                return <div key={state}>{state} {list.length}</div>
                            } else {
                                return <div key={state}>{state} {list.filter(item => item.state === state).length}</div>
                            }
                        })}
                        <div>filtred: {filtList.length}</div>
                    </Col>
                </Row>
            </Container>
}

export default Broadcasts
