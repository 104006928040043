import React, { useState } from 'react'
import Block from '@components/block'
import blocks from './blocks'

const ClubItem = ({ data, onUpdatedItem }) => {
    return  <div className='item-col'>
                <h4>{data.name}</h4>
                {blocks.map(b => (
                    <Block
                        data={data}
                        config={b}
                        onUpdatedItem={onUpdatedItem}
                        splitted={b.splitAgeGroups && data.agegroups && data.agegroups.length}
                    />
                ))}
            </div>
}

export default ClubItem
