import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
 Col,
 Form,
 FormInput,
 FormSelect,
 FormGroup,
 InputGroup,
 InputGroupAddon,
 InputGroupText,
 Button
} from "shards-react";
import Role from './Role';
import AddRoleForm from './AddRoleForm';
import { diff } from 'deep-object-diff';

const rest = require('../Workspace/rest');
const dot = require('dot-object');

const userSchema = {
    tid: '',
    first_name: '',
    last_name: '',
    email: '',
    roles: [],
    broadcastDefaults: {countFouls: true, staticFouls: true},
    invite: null,
    activeSubject: null,
}
const roleClubSchema = {
    role: 'admin',
    seasonId: null,
    dataSourceId: null,
    clubId: null,
}
const roleLeagueSchema = {
    role: 'admin',
    leagueId: null,
    keyboard: []
}

const AddUserForm = ({user, toBotUsers}) => {
    const [botUser, setBotUser] = user ? useState(user): useState(userSchema);
    const [updUser, setUpdUser] = useState({...botUser});
    const [difUser, setDifUser] = useState(null);

    const [visibleForm, setVisibleForm] = useState(true);
    const [userRole, setUserRole] = useState(null);

    const [userRoles, setUserRoles] = useState(botUser.roles);

    useEffect(() => {
        setDifUser(diff(botUser, updUser));
    }, [botUser, updUser]);

    const handleField = (evt) => {
        let key = evt.target.name;
        setUpdUser({
            ...updUser,
            [key]: evt.target.value
        });
    }

    const rand = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const createUser = (user) => {
        const invite = rand(10000, 99999);
        console.log('createUser',invite);
        rest('post', 'botusers', null, {...user, invite: invite})
            .then(created => {
                setBotUser(created);
                setUpdUser(created);
                setUserRoles(created.roles)
            })
            .catch(e => console.log(e))
    }

    const changeUser = (diff) => {
        rest('put', 'botusers', updUser._id, {...diff})
            .then(upd => {
                setBotUser({...updUser});
                setUpdUser({...updUser});
            })
            .catch(e => console.log(e))
    }

    const addRole = (role, clubRole=false) => {
        setUserRole({...role, clubRole: clubRole});
        setVisibleForm(false);
    }

    const delRole = role => {
        let indx;
        if (role.hasOwnProperty('clubId') && role.clubId) {
            if (role.hasOwnProperty('seasonId') && role.seasonId) {
                indx = userRoles.findIndex(item => item.clubId === role.clubId && item.seasonId === role.seasonId);
            } else {
                indx = userRoles.findIndex(item => item.clubId === role.clubId && item.seasonId === role.seasonId);
            }
        } else {
            indx = userRoles.findIndex(item => item.leagueId === role.leagueId);
        }
        rest('put', 'botusers', botUser._id, {'roles': userRoles.filter((role, ind) => ind !== indx)})
            .then(upd => {
                if (upd && upd.roles) {
                    setUserRoles(upd.roles);
                }
            })
            .catch(e => console.log(e))
    }
    const toBotUser = id => {
        rest('get','botusers', id)
            .then(botuser => {
                if (botuser) {
                    setUserRoles(botuser.roles);
                    setUpdUser(botuser);
                    setBotUser(botuser);
                    setDifUser(null);
                    setUserRole(null);
                    setVisibleForm(true);
                }
            })
            .catch(e => console.log(e))
    }

    const isEmptyOrNull = obj => obj === null || Object.keys(obj).length === 0;

    return (
        visibleForm ?
            <Col sm="12" md="6">
              <strong className="text-muted d-block mb-2">User</strong>
              <Form>
                <FormGroup>
                  <InputGroup className="mb-3">
                    <FormInput
                        id = 'first_name'
                        name = 'first_name'
                        placeholder="first name"
                        value={updUser ? updUser.first_name : ''}
                        onChange={handleField}
                        autoComplete='off'
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <FormInput
                    id = 'last_name'
                    name = 'last_name'
                    placeholder="last_name"
                    value={updUser ? updUser.last_name : ''}
                    onChange={handleField}
                    autoComplete='off'
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    id = 'tid'
                    name = 'tid'
                    placeholder="tid"
                    value={updUser ? updUser.tid : ''}
                    onChange={handleField}
                    autoComplete='off'
                  />
                </FormGroup>
                <Row form>
                  <Col md="7">
                    <FormInput
                        id = 'email'
                        name = 'email'
                        value={updUser ? updUser.email ? updUser.email : '' : ''}
                        onChange={handleField}
                        autoComplete='off'
                        placeholder="email"
                    />
                  </Col>
                </Row>
                {userRoles.length ?
                    <Container>
                        {userRoles.map(role => {
                            if (role.hasOwnProperty('clubId')  && role.clubId) {
                                const club = updUser.rel.clubs.find(club => club._id.toString() === role.clubId);
                                let season;
                                let dataSource;
                                if (role.hasOwnProperty('seasonId')  && role.seasonId) {
                                    season = updUser.rel.seasons.find(season => season._id.toString() === role.seasonId);
                                } else {
                                    dataSource = updUser.rel.datasources.find(dataSource => dataSource._id.toString() === role.dataSourceId);
                                }
                                return {
                                    ...role,
                                    club: club,
                                    dataSource: dataSource ? dataSource : null,
                                    season: season ? season : null,
                                    name: season ? `${club.name}. ${season.name}` : `${club.name}. ${dataSource.remoteMeta.name}`
                                }
                            } else /*if (role.leagueId)*/ {
                                const league = updUser.rel.leagues.find(league => league._id.toString() === role.leagueId);
                                return {
                                    ...role,
                                    league: league,
                                    name: league.name
                                }
                            } /*else {
                                return role
                            }*/
                        }).map(role => <Role
                                key={role.clubId ? role.clubId : role.leagueId}
                                role={role}
                                delRole={() => delRole(role)}
                                changeRole={role.clubId ? null : () => addRole(role, false)}
                            />)
                        }
                    </Container>
                : null
                }
                <Row className="d-flex">
                    <Button
                        pill
                        active
                        onClick = {() => addRole(roleClubSchema, true)}
                        theme="success"
                        disabled={!botUser._id}
                    ><i className="material-icons">add</i> Add club role </Button>
                    <Button
                        pill
                        active
                        onClick = {() => addRole(roleLeagueSchema)}
                        theme="success"
                        disabled={!botUser._id}
                    ><i className="material-icons">save</i> Add league role </Button>
                </Row>
                <Button
                    pill
                    active
                    onClick = {() => user ? changeUser(difUser) : createUser(updUser)}
                    theme="secondary"
                    disabled={isEmptyOrNull(difUser)}
                > {user ? 'Change user': 'Add user'} </Button>
                <Button
                    pill
                    active
                    onClick = {toBotUsers}
                    theme="primary"
                > to list </Button>
              </Form>
            </Col>
        :   <AddRoleForm
                role={userRole}
                toBotUsers={toBotUsers}
                toBotUser={toBotUser}
                botUser={updUser}
                roleClub={roleClubSchema}
                roleLeague={roleLeagueSchema}
                clubRole={userRole ? userRole.clubRole : false}
            />
    )
}

export default AddUserForm;
