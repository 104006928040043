import React, {useState, useEffect} from "react";
import { Row, Col, Card, CardHeader, CardBody, FormSelect, FormRadio } from '@ui'
import { ProgressBar, NonIdealState } from '@common'
import CustomScrollbars from 'react-custom-scrollbars-2'
import Loader from '../loader';
import ClubCard from './ClubCard';
import ClubItem from './ClubItem';
import PlayerCard from './PlayerCard';
import PlayerItem from './PlayerItem';
import './style.css';
import { observable, action, computed, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
const { REST } = require('../../env')
const base = `${REST}references/`;
const axios = require('axios');

const filters = [
    {name: 'all', descr: 'Все'},
    {name: 'warn', descr: 'Требуют вмешательства'},
    {name: 'moderated', descr: 'Отредактированные'},
    {name: 'notmoderated', descr: 'Неотредактированные'},
];

// const filters = [
//     'all', 'warn', 'moderated', 'notmoderated'
// ];
const References = inject('mainStore')(observer(({ mainStore, props }) => {
    let leagues = computed(() => mainStore.getMainStore('leagues')).get();

    const [entryId, setEntryId] = useState(null);
    const [league, setLeague] = useState(null);
    const [clubs, setClubs] = useState([]);
    const [players, setPlayers] = useState([]);
    //const [clubPlayers, setClubPlayers] = useState([]);
    const [club, setClub] = useState(null);
    const [player, setPlayer] = useState(null);
    const [curClub, setCurClub] = useState(null);
    const [curPlayer, setCurPlayer] = useState(null);

    const [loading, setLoading] = useState(false);
    const [clubTerm, setClubTerm] = useState('');
    const [playerTerm, setPlayerTerm] = useState('');
    const [filterPlayers, setFilterPlayers] = useState('all');
    const [filter, setFilter] = useState('all');
    const [filtredClubs, setFiltredClubs] = useState(clubs);
    const [filtredPlayers, setFiltredPlayers] = useState(players);

    useEffect(() => {
        if(entryId && entryId !== 'all') {
            setLoading(true);
            setClubs([]);
            setFiltredClubs([]);
            setPlayers([]);
            //setClubPlayers([]);
            setFilterPlayers([]);
            setClub(null);
            setPlayer(null);
            const url = `${base}${entryId}`;
            getData(url);
        }
    }, [entryId])

    useEffect(() => {
        if (!club || (curClub && curClub._id !== club._id)) {
            setClub(curClub);
        }
        if (curClub) {
            //const curclubplrsId = curClub.squad.players.map(item => item._id);
            //const curClubPlrs = players.filter(item => curclubplrsId.includes(item._id.toString()));
            //console.log('curClub',curClub);
            //console.log('curClubPlrs',curClubPlrs);
            setPlayers(curClub.squad.players);
            //setClubPlayers(curClub.squad.players);
            setFilterPlayers('all');
            setFiltredPlayers(curClub.squad.players);
        } else {
            //setClubPlayers([]);
            setFiltredPlayers([]);
            setFilterPlayers('all');
        }//"http://img.goalstream.org/img_c_26x26/public/gperson_person/d5/d2/2b/2b7c2e_6848.png?c=e600"
    }, [curClub])

    useEffect(() => {
        if (!player || (curPlayer && curPlayer._id !== player._id)) {
            setPlayer(curPlayer);
        }
    }, [curPlayer])

    useEffect(() => {
        //console.log('useEffect', filter);
        setClub(null);
        switch (filter) {
            case 'all':
                setFiltredClubs(clubs);
                break;
            case 'warn':
                setFiltredClubs(clubs.filter(item => item.moderatedwarn));
                break;
            case 'moderated':
                setFiltredClubs(clubs.filter(item => item.moderated));
                break;
            case 'notmoderated':
                setFiltredClubs(clubs.filter(item => !item.moderated));
                break;
            default:
                break;
        }
    }, [filter]);

    useEffect(() => {
        setPlayer(null);
        switch (filterPlayers) {
            case 'all':
                setFiltredPlayers(players);
                break;
            case 'warn':
                setFiltredPlayers(players.filter(item => item.moderatedwarn));
                break;
            case 'moderated':
                setFiltredPlayers(players.filter(item => item.moderated));
                break;
            case 'notmoderated':
                setFiltredPlayers(players.filter(item => !item.moderated));
                break;
            default:
                break;
        }
    }, [filterPlayers]);

    const getData = (url) => {
        //const strparams = isObj(params) ? qs.stringify(params) : '';
        //const url = strparams ? `${base}${endpoint}?${strparams}` : `${base}${endpoint}`;
        setLoading(true);
        axios(url)
            .then(response => {
                //console.log('widget', response.data.clubs);
                setClubs([...response.data]);
                setFiltredClubs([...response.data]);
                //setPlayers([...response.data.players]);
                //setFiltredPlayers([...response.data.players]);

                //отключение лоадера должно происходить по факту получения, а не в useEffect инициирующем запрос
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const onChangeEntryId = ({target}) => {
        const leag = leagues.find(item => item._id.toString() === target.value);
        setLeague(leag.name);
        setEntryId(target.value);
    }

    const selectData = (data) => {
        //if (data['birthday'] !== 'undefined') {
        if (data.hasOwnProperty('birthday')) {
            if (!player || data._id.toString() !== player._id.toString()) {
                setPlayer(null);
                setCurPlayer(data);
                setPlayerTerm('');
            }
        } else {
            //console.log(data, data['bithday']);
            if (!club || data._id.toString() !== club._id.toString()) {
                setClub(null);
                setCurClub(data);
                setClubTerm('');
            }
        }
    }
    const mapList = (list, item) => {
        const mapdList = list.map(elem => {
            if (elem._id === item._id) {
                return item
            } else {
                return elem
            }
        })
        return mapdList;
    }

    const updList = (item, player=false) => {
        //console.log('updList',item, player);
        if (player) {
            const mapdList = mapList(players, item);
            //console.log('updList', mapdList);
            setPlayers(mapdList)
            const mapdFiltList = mapList(filtredPlayers, item);
            setFiltredPlayers(mapdFiltList);
            //setClubPlayers(mapdClubPlList);
        } else {
            const mapdList = mapList(clubs, item)
            //console.log('updList club', mapdList);
            setClubs(mapdList);
            setFiltredClubs(mapdList);
        }
        //return mapList;
    }

    // const changeFilter = (name) => {
    //     console.log('changeFilter', name);
    //     setFilter(name)
    // }

    return (
        <div className="referencesContainer">
            <div className="page-header py-4">
              <h3 className="page-title">Подготовка графики</h3>
            </div>

            <Row className="border-bottom py-2 bg-light" >
              <Col style={{ maxWidth: "350px" }}>
                    {leagues.length ?
                        <FormSelect
                            size="sm"
                            value={entryId ? entryId : 'all'}
                            style={{ maxWidth: "350px" }}
                            onChange={(evt) => onChangeEntryId(evt)}
                        >
                        {leagues.map(item => {
                            //let key = Object.keys(item)[0];
                            return <option key={item._id} value={item._id}>{item.name}</option>
                        })}
                        </FormSelect>
                    : <Loader style={{margin: 'auto'}}/>}
              </Col>
              {clubs.length ? <Col>
                  <div>
                      {filters.map(item => (
                          <FormRadio
                              key={'clubs_'+item.name}
                              inline
                              name="clubs"
                              checked={filter === item.name}
                              onChange={() => setFilter(item.name)}
                          >{item.descr}</FormRadio>
                      ))}
                  </div>
                  <div>клубов в списке: {filtredClubs.length}</div>
              </Col> : null}
            </Row>
            {(entryId && entryId !== 'all') ? (
                <Row style={{marginTop: 20}} className='content'>
                    <Col className='list-col'>
                        <CustomScrollbars
                            autoHeight
                            autoHeightMin='calc(100vh - 300px)'
                        >
                            {loading ? <ProgressBar label='загрузка клубов' /> :
                                filtredClubs.length ? (
                                    <div className='grid g1'>
                                    {filtredClubs.map(club => {
                                        return <ClubCard
                                            key={club._id}
                                            data={club}
                                            selectData={selectData}
                                            selected={curClub && club._id === curClub._id ? true : false }
                                            warning={club.moderatedwarn}
                                            league={league}
                                        />
                                    })}
                                </div>
                            ) : (
                                <NonIdealState label='нет подходящих клубов' />
                            )}
                        </CustomScrollbars>
                    </Col>

                    <Col>
                        <CustomScrollbars
                            autoHeight
                            autoHeightMin='calc(100vh - 300px)'
                        >
                            {club ? <ClubItem data={club} updList={updList} league={league}/> : filtredClubs.length ? <NonIdealState label='клуб не выбран' /> : null}
                        </CustomScrollbars>
                    </Col>

                    <Col className='list-col'>
                        {curClub && players.length ? ([
                            <div>
                                {filters.map(item => (
                                    <FormRadio
                                        key={'players_'+item.name}
                                        inline
                                        name="players"
                                        checked={filterPlayers === item.name}
                                        onChange={() => setFilterPlayers(item.name)}
                                    >{item.descr}</FormRadio>
                                ))}
                                <div>игроков в списке: {filtredPlayers.length} </div>
                            </div>,
                            <CustomScrollbars
                                autoHeight
                                autoHeightMin='calc(100vh - 450px)'
                            >
                                <div className='grid'>
                                    {filtredPlayers.map(player =>(
                                        <PlayerCard
                                            key={player._id}
                                            selectData={selectData}
                                            data={player}
                                            selected={curPlayer && player._id === curPlayer._id ? true : false }
                                            warning={player.moderatedwarn}
                                        />)
                                    )}
                                </div>
                            </CustomScrollbars>
                        ]) : <div style={{width: 300}}></div>
                        }
                    </Col>

                    <Col>
                        {curClub && players.length ? player ? <PlayerItem data={player} updList={updList}/> : <NonIdealState icon='person' label='игрок не выбран' /> : <div style={{width: 300}}></div>}
                    </Col>
                </Row>
            ) : (<NonIdealState label='лига не выбрана' />)}
        </div>
    )
}))

export default References;
