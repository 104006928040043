// import React from "react";
//
// const AreaChart = (props) => {
//     //console.log('AreaChart', props);
//     return <div>{props.data ? props.data.data : ''} {props.endpoint}</div>
// }
//
// export default AreaChart;

import React, { useEffect, useState, useRef} from "react";
import { CardBody } from "shards-react";

import Chart from "../../utils/chart";

const options = {
  ...{
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false,
      custom: false
    },
    elements: {
      point: {
        radius: 0
      },
      line: {
        tension: 0.33
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: false,
          ticks: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          gridLines: false,
          scaleLabel: false,
          ticks: {
            display: false,
            isplay: false,
            // Avoid getting the graph line cut of at the top of the canvas.
            // Chart.js bug link: https://github.com/chartjs/Chart.js/issues/4790
            suggestedMax: 1
          }
        }
      ]
    }
  },
};
const config = {
    type: "line",
    data: {
        labels: [null, null, null, null, null, null],
        datasets: []
    },
    options: options
};

const AreaChart = ({data, borderColor, backgroundColor, ...props}) => {
    const canvasRef = useRef('');
    const [chOptions, setChOptions] = useState(options);
    const [chConfig, setChConfig] = useState(config);

    useEffect(() => {
        if (data && data.data) {
            const axes = [...chOptions.scales.yAxes];
            axes[0].ticks = {...axes[0].ticks, suggestedMax: data ? Math.max(...data.data) + 1 : 1};
            setChOptions( {
                ...chOptions,
                scales: {...chOptions.scales,
                    yAxes: axes
                }
            })
            const dataChart = [{
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor,
                borderColor,
                data: data.data
            }];

            setChConfig({
                ...chConfig,
                ...{
                    data: {
                        ...chConfig.data,
                        datasets: [...dataChart]
                    },
                    options: chOptions
                }
            })
        }
    }, [data])

    new Chart(canvasRef.current, chConfig);

      const canvasHeight = 120;
//console.log('canvasRef area', canvasRef)
      return (
          <CardBody style={{ minHeight: props.minHeight, textAlign:'center', width: '100%', padding: 0, marginTop: '1.5rem'}} >
          {/*<div style={{display: 'flex', flexDirection: 'column', alignItems:'center', margin: '0 auto'}}>*/}
              <div style={{ textAlign:'center', width: '100%', padding: 0, margin: 'auto'}}>
                <div style={{ fontSize: '1rem', fontWeight:'500', textTransform:'uppercase'}}>{props.title}</div>
                <h6 style={{fontSize: '2rem', fontWeight:'500', lineHeight: '1.5rem', margin: '1rem 0'}}>{data ? data.value : ''}</h6>
              </div>
              <div style={{textAlign: 'center'}}>
                  <span className={ data ? data.increase ? 'percentage increase' : 'percentage decrease' : ''}>{data ? data.percentage : ''}</span>
              </div>
              <canvas
                height={canvasHeight}
                ref={canvasRef}
                className={`areaChart_canvas`}
              />
          </CardBody>
      );
}

export default AreaChart;
