import React from "react";
import PageTitle from "./PageTitle";
import Widget from "../widgets";
import { observable, action, computed, toJS } from 'mobx';
import widgets from './widgets'
import { inject, observer } from 'mobx-react'

const LeaguesDashboard = inject('mainStore')(observer(({ mainStore, props }) => {
    let leagues = computed(() => mainStore.getMainStore('leagues')).get();
    return (
        <div className="leaguesDashboardContainer" style={{width: '1280px', boxSizing:'border-box', margin: '0 auto'}}>
            <div className="page-header py-4">
              <PageTitle title="Amateum Leagues" subtitle="Статистика" className="text-sm-left mb-3" />
            </div>

            {widgets.map((item, idx) => {
                const schema = {
                    gridTemplateColumns: '',
                    minHeight: item[0].minHeight
                };
                for (let i=0; i< item.length; i++) {
                    schema.gridTemplateColumns += `${item[i].width}fr `;
                }
                return <div key={idx} style={{minHeight: schema.minHeight, display: 'grid', gridTemplateColumns: schema.gridTemplateColumns, gap: '2%', marginBottom: '26px'}}>
                    {item.map(i => {
                        return (
                            <Widget key={i.title} minHeight= {schema.minHeight} {...i} leagues={leagues}/>
                        )
                    })}
                </div>
            })}
        </div>
    )
}))


export default LeaguesDashboard;
