import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
} from "shards-react";

const Persons = (props) => {
    const {data, title, params} = props
    const periodsReverse = params.filters.periods.reverse();
    return data ?
    <Card small className="blog-comments">
      <CardHeader className="title">
        <h6 className="m-0">{title}</h6>
      </CardHeader>

      <CardBody className="p-0">
        {data.map((pers, idx) => (
          <div key={pers.tid} className={'periods'}>
            <div className={'text-secondary periods__name'}>{pers.name}</div>
            <div className={'periods__item'}>{periodsReverse.map(item => {
                const per = Object.entries(item)[0];
                const val = pers.data.find(d => d.period === per[0]);
                console.log(item)
                return <div key={`${pers.tid}.${item.period}`}>
                    <div className={'period text-mutate'}>{per[1]}</div>
                    <div className={'value'}>{val ? val['count'] : 0}</div>
                </div>
            })}</div>
          </div>
        ))}
      </CardBody>
     </Card>
    : null;
}

export default Persons;
