import {action, makeObservable, observable, autorun, toJS, reaction, computed} from 'mobx';
const axios = require('axios');
const { REST } = require('../env')
//const base = `${REST}`;

class MainStore {
    mainStore = {
        leagues: []
    }

    constructor(props) {
        makeObservable(this, {
            mainStore: observable,
            setMainStore: action
        })
        this.initStore()
    }
    initStore = () => {
        this.getLeagues()
    }

    getMainStore = (key) => {
        if (typeof (this.mainStore[key]) === 'undefined'){
            return false
        }else{
            return toJS(this.mainStore[key])
        }
    }

    setMainStore = (key, value) => {
        this.mainStore[key] = value;
    }

    getLeagues = () => {
        const url = `${REST}leaguesonlys`;
        axios(url)
            .then(response => {
                this.setMainStore('leagues', [{_id: 'all', name: 'По всем лигам'}, ...response.data])
            })
            .catch(err => {
                console.log(err);
            });
    };
    //autorun(())
}

export default new MainStore();
