import React, { useState, useEffect } from 'react'
import { EntityButton } from '@common'
import rest from '@rest'
import { remoteSources } from '@refs'

const SourceItem = ({ data, agegroups }) => {
    const [_data, setData] = useState({...data})

    const parseSeason = f => {
        rest('put', 'datasources', _data._id, {
            ownerRemoteId: parseInt(f.ownerRemoteId.value),
            ownerAgeGroup: f.agegroup.value
        }).then(upd => {
            rest('put', 'parsers/mosff', `datasource?dataSourceId=${_data._id}`)
                .then(parsed => {
                    setData(parsed)
                })
        })
    }

    return  <div>
                {_data.remoteMeta ? (
                    <div style={{display: 'flex'}}>
                        <div>{_data.remoteMeta.name} / {_data.ownerAgeGroup}</div>
                    </div>
                ) : ([
                    <span>{_data.remoteSource} / {_data.remoteId}</span>,
                    <EntityButton
                        theme='info'
                        cta='Парсить команду и соперников'
                        init={{
                            ownerRemoteId: {type: 'text', value: '', placeholder: 'ID'},
                            agegroup: {type: 'select', options: agegroups}
                        }}
                        onSubmit={f => {
                            parseSeason(f)
                        }}
                    />
                ])}
            </div>
}

const DatasourcesBlock = ({ value }) => {
    const [list, setList] = useState(null)

    useEffect(() => {
        setList(null)
        rest('get', 'datasources', `?filter[clubId]=${value._id}`)
            .then(sources => {
                setList(sources)
            })
    }, [value._id])

    const createSource = form => {
        rest('post', 'datasources', null, {
            clubId: value._id,
            remoteSource: form.remoteSource.value,
            remoteId: parseInt(form.remoteId.value)
        }).then(entity => {
            setList(list.concat(entity))
        })
    }

    return  list ? <div>
                <div>
                    {list.map(ds => (<SourceItem data={ds} agegroups={value.agegroups.map(ag => ({value: ag.year, label: ag.year}))} />))}
                </div>

                <EntityButton
                    init={{
                        remoteId: {type: 'text', placeholder: 'Внешний ID', value: ''},
                        remoteSource: {type: 'select', options: remoteSources, value: null}
                    }}
                    cta='Добавить источник данных'
                    onSubmit={f => createSource(f)}
                />
            </div> : null
}

export default DatasourcesBlock
