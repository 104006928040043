import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import BotUsers from "./components/bot-users/BotUsers";
import Clubs from './views/Clubs'
import Broadcasts from './components/broadcasts/Broadcasts'
import LeaguesDashboard from "./components/leagues-dashboard/LeaguesDashboard";
import References from './components/references/References'

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/blog-overview" />
  },
  {
    path: "/bot-users",
    layout: DefaultLayout,
    component: BotUsers
  },
  {
    path: "/broadcasts",
    layout: DefaultLayout,
    component: Broadcasts
  },
  {
    path: '/clubs/:itemId?',
    layout: DefaultLayout,
    component: Clubs
  },
  {
    path: '/leagues-dashboard',
    layout: DefaultLayout,
    component: LeaguesDashboard
  }, {
    path: '/references',
    layout: DefaultLayout,
    component: References
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  }
];
