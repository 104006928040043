import React from 'react'
import { Card, CardBody, CardTitle } from '@ui'

const ClubCard = ({ data, selectData, selected, warning, league }) => {
    const emblem = (league && league.includes('OLE')) ? (data.emblem || `https://amateum.fra1.cdn.digitaloceanspaces.com/clubs/ole/${data.remote.id}.png`) : data.emblem
    return  data ? <Card className={`inter${selected ? ' selectedcard' : ''} ${warning ? 'warningcard' : ''}`} onClick={() => selectData(data)} style={{maxHeight: "80px", position:'relative'}}>
                <div className='card-emb' style={{backgroundImage: `url(${emblem})`}}></div>
                <CardBody className='compact'>
                    <CardTitle>{data.name}</CardTitle>
                    <div className='clubfrom'>
                        <p>{data.from ? data.from.city : null}</p>
                        <p>{data.moderated ? data.moderated : null}</p>
                    </div>
                    <div className='clubpreview'>
                        <div>Игроков: {data.allplrs}</div>
                        <div>С меткой: {data.warnplrs}</div>
                        <div>Проверено: {data.moderplrs}</div>
                        <div>Непроверено: {data.notmoderplrs}</div>
                    </div>
                </CardBody>
            </Card> : null
}

export default ClubCard
