// const blocks = [
//     {
//         title: 'Основное',
//         entity: 'clubs',
//         fields: [
//             [
//                 {
//                     label: 'Название',
//                     type: 'text',
//                     path: 'name'
//                 },
//                 {
//                     label: 'Локация',
//                     type: 'text',
//                     path: 'from.city',
//                 },
//             ], [
//                 {
//                     label: 'Эмблема',
//                     type: 'photo',
//                     path: 'emblem'
//                 }
//             ]
//         ]
//     },
//     {
//         title: 'Визуальные настройки',
//         entity: 'clubs',
//         fields: [
//             [
//                 {
//                     label: 'Шаблон',
//                     type: 'select',
//                     path: 'botConfig.template',
//                     options: [
//                         {label: '-- не назначен', value: 'null'},
//                         {label: 'Plastic', value: 'plastic'},
//                         {label: 'Flat', value: 'flat'},
//                         {label: 'Junior', value: 'junior'},
//                         {label: 'Clean', value: 'clean'}
//                     ]
//                 }
//             ]
//         ]
//     },
//     {
//         title: 'Фото-пресеты',
//         entity: 'clubs',
//         cardSuffix: 'gallery-card',
//         splitAgeGroups: true,
//         fields: [
//             [
//                 {
//                     type: 'gallery',
//                     path: 'botConfig.photoPresets'
//                 }
//             ]
//         ]
//     }
// ]

const blocks = [
    {
        title: 'Источники данных',
        entity: 'datasources',
        loadable: true,
        fields: [
            {
                type: 'datasources',
                path: 'root'
            }
        ]
    },
    {
        title: 'Возрастные группы',
        entity: 'clubs',
        fields: [
            {
                type: 'agegroups',
                path: 'agegroups'
            }
        ]
    },
    {
        title: 'Визуальные настройки',
        entity: 'clubs',
        fields: [
                {
                    label: 'Шаблон',
                    type: 'select',
                    path: 'botConfig.template',
                    options: [
                        {label: '-- не назначен', value: 'null'},
                        {label: 'Plastic', value: 'plastic'},
                        {label: 'Flat', value: 'flat'},
                        {label: 'Junior', value: 'junior'},
                        {label: 'Clean', value: 'clean'},
                        {label: 'Avangard', value: 'avangard'}
                    ]
                }
        ]
    },
    {
        title: 'Фото-пресеты',
        entity: 'clubs',
        cardSuffix: 'gallery-card',
        splitAgeGroups: true,
        fields: [
                {
                    type: 'gallery',
                    path: 'botConfig.photoPresets'
                }
        ]
    }
]

export default blocks
