import React, { useState, useEffect } from 'react'
// import Block from '@components/block'
// import blocks from './blocks'
import { Form, FormInput, InputGroup,InputGroupAddon, InputGroupText, Button, FormCheckbox } from "shards-react";
import { ProgressBar } from '@common'
import rest from '@rest'

import utils from '../../utils/utils';
const moment = require ('moment');
moment.locale('ru');
//console.log(utils);
//const base = 'https://amateum.fra1.cdn.digitaloceanspaces.com/presets/'

const ClubItem = ({ data, updList, league }) => {
    const [club, setClub] = useState(data);
    const [name, setName] = useState(club.name || '');
    const [city, setCity] = useState(club.from ? club.from.city : '');
    const [emblem, setEmblem] = useState('');
    const [newEmblem, setNewEmblem] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [warn, setWarn] = useState(club.moderatedwarn || false)
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (league) {
            if (league.includes('OLE')) {
                   setEmblem(club.emblem || `https://amateum.fra1.cdn.digitaloceanspaces.com/clubs/ole/${club.remote.id}.png`)
            } else {
                setEmblem(club.emblem)
            }
        }
    }, [league])
    // {name: { $regex:'17 Лицей 2005', $options: 'ig' }}
    // "/public/gclub_club/12/d1/1e/1e945f_1268.png?c=a941"

    // {/*<div className='item-col'>
    //             <h4>{data.name}</h4>
    //             {blocks.map(b => (
    //                 <Block
    //                     data={data}
    //                     config={b}
    //                     onUpdatedItem={onUpdatedItem}
    //                     splitted={b.splitAgeGroups && data.agegroups && data.agegroups.length}
    //                 />
    //             ))}
    //         </div>*/}
    const setData = ({target}) => {
        switch (target.name) {
            case 'name':
                setName(target.value)
                break;
            case 'city':
                setCity(target.value)
                break;
            default:
                break;
        }
    }

    const updateClub = (danger=false) => {
        const date = moment().format('YY-MM-DD');
        const updclub = {...club, name: name, moderated: date};
        const form = {name: name, moderated: date};
        if (danger) {
            //console.log('danger',updclub);
            setWarn(!warn)
        } else {
            if (newEmblem) {
                updclub.emblem = newEmblem;
                form.emblem = newEmblem;
                setEmblem(newEmblem);
                setNewEmblem(null);
            }
            updclub.moderatedwarn = warn;
            form.moderatedwarn = warn;

            if (city) {
                updclub['from.city'] = city;
                form['from.city'] = city;
            } else {
                updclub['from.city'] = null;
                form['from.city'] = null;
            }

            //console.log('upd',updclub,'form',form);
            setSaving(true)
            rest('put', 'clubs', updclub._id, form)
                .then(upd => {
                    //console.log('upd rest',upd)
                    setClub(updclub);
                    setSaving(false);
                    updList(updclub)
                    //setInit({...form})
                })
                .catch(e => console.log(e))
        }
    }
    const changeEmblem = async (action, url) => {
        //_.uploadFromRemoteUrl = async (url, benz=true, folder='presets', save=false ) => {
        let imgUrl;
        setUploading(true)
        switch (action) {
            case 'delAndCute':
                imgUrl = await utils.uplImgToDO({target: 'presets', benzin: true, defImgUrl: url});
                //console.log('changeEmblem', url, 'new', imgUrl);
                setNewEmblem(imgUrl);

                /*rest('put', 'uploader', null, {target: 'presets', benzin: true, defImgUrl: url})
                    .then(upl => {
                        //setUploading(false)
                        console.log(upl);
                        //patch([`${base}${filename}.${ext}`].concat(value))
                        //url = `${base}${filename}.${ext}`;
                        imgUrl = upl.url
                        console.log(imgUrl);
                        setNewEmblem(imgUrl);
                    })
                    .catch(e => console.log(e));*/
                break;
            case 'cute':
                imgUrl = await utils.uplImgToDO({target: 'presets', benzin: true, defImgUrl: url, trim: true});
                console.log('cute changeEmblem', url, 'new', imgUrl);
                setNewEmblem(imgUrl);
                break;
            case 'temlate':
                imgUrl = `${utils.base}presets/34a9458c-831a-4371-a55b-86ec25b04f46.png`;
                setNewEmblem(imgUrl);
                break;
            default:
                break;
        }
        setUploading(false)
        //return imgUrl;
    }

    const handleAttach = async e => {
        setUploading(true)
        const reader = new FileReader()
        let url;
        reader.onload = async () => {
            const filename = utils.uuidv4();
            const ext = e.target.files[0].type.includes('png') ? 'png' : 'jpg';
            url = await utils.uplImgToDO({target: 'presets', filename: filename, ext: ext, data: reader.result, benzin: true})
            //console.log(url);
            setNewEmblem(url);
            /*rest('put', 'uploader', null, {target: 'presets', filename: filename, ext: ext, data: reader.result, benzin: true})
                .then(upl => {
                    //setUploading(false)
                    console.log(upl);
                    //patch([`${base}${filename}.${ext}`].concat(value))
                    //url = `${base}${filename}.${ext}`;
                    url = upl.url
                    console.log(url);
                    setNewEmblem(url);
                })
                .catch(e => console.log(e))
                .finally(e => setUploading(false))*/
            setUploading(false);
        }
        //console.log('handleAttach', e.target.files[0]);
        //console.log('handleAttach', reader.readAsDataURL(e.target.files[0]));
        reader.readAsDataURL(e.target.files[0])
        //return url ? url : '';
    }

    return  (
        <>
        <h5>Подготовка клуба</h5>
        <Form>
            <InputGroup>
                <InputGroupAddon type="prepend">
                    <InputGroupText>Название</InputGroupText>
                </InputGroupAddon>
                <FormInput
                    name='name'
                    placeholder="name"
                    onChange={setData}
                    value={name}
                    autoComplete='off'
                />
            </InputGroup>
            <InputGroup style={{marginTop: 8}}>
                <InputGroupAddon type="prepend">
                    <InputGroupText>Город</InputGroupText>
                </InputGroupAddon>
                <FormInput
                    name='city'
                    placeholder="city"
                    onChange={setData}
                    value={city}
                    autoComplete='off'
                />
            </InputGroup>
        </Form>
        <div className='col-controls'>
            <h6>Эмблема</h6>
            {emblem ? <div className='emblems' style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                {newEmblem ? <img style={{ width: '100px', height:'100px'}} src={newEmblem} alt='newEmblem'/> : <><img style={{ width: '100px', height:'100px'}} src={emblem} alt='emblem'/>
                <img style={{ width: '150px', height:'150px'}} src={emblem} alt='emblem'/></>}
            </div> : <div>club without emblem</div>}
            <div className='actions'>
                {uploading ? <ProgressBar label='обработка, ничего не нажимай =)' /> : null}
                <Button disabled={uploading} className='references-upl-btn' outline>
                    <i className='material-icons'>{uploading ? 'hourglass_bottom' : 'add_circle_outline'}</i>
                    <input type='file' onChange={handleAttach} />
                    Загрузить с диска
                </Button>
                <Button theme='info' disabled={uploading || !emblem} outline onClick={() => changeEmblem('delAndCute', emblem)}>Удалить фон и обрезать альфа-канал</Button>
                <Button theme='primary' disabled={uploading || !emblem} outline  onClick={() => changeEmblem('cute', emblem )}>Обрезать альфа-канал</Button>
                <Button theme='secondary' disabled={uploading || !emblem} outline onClick={() => changeEmblem('temlate')}>Установить заглушку</Button>
                {/*<Button theme='light'><input type='file' onChange={() => changeEmblem('upload')} />Загрузить</Button>*/}
                {/*<Button theme='light' onClick={() => changeEmblem('upload')}><input type='file' onChange={() => changeEmblem('upload')} />Загрузить</Button>*/}
                {/*<Button theme='danger' disabled={uploading} outline onClick={() => updateClub(true)}>{warn ? 'Вмешательство больше не трубется' : 'Требует вмешательства'}</Button>*/}
                <FormCheckbox
                    toggle
                    checked={warn}
                    onChange={() => updateClub(true)}
                    //style={{display: 'flex', justifyContent: 'space-around', flexDirection:'column', alignItems: 'center'}}
                >Требует ручного вмешательства</FormCheckbox>
            </div>
            <Button theme='success' disabled={uploading || saving} outline onClick={() => updateClub()}>Обновить данные</Button>
        </div>
        </>
    )
}

export default ClubItem
