import React, { useState, useEffect } from "react";
import { Card } from "shards-react";

import AreaChart from './AreaChart';
import MixedChart from './MixedChart';
import Persons from './Persons';
import PieChart from './PieChart';
import TopList from './TopList';
import Loader from '../loader';
import SmallStats from './SmallStats';
//import { observer, injection } from 'mobx-react';
const axios = require('axios');
//const rest = require('../Workspace/rest');
const qs = require('qs');

const types = {
    areaChart: AreaChart,
    mixedChart: MixedChart,
    persons: Persons,
    pieChart: PieChart,
    topList: TopList,
    smallStats: SmallStats
}
const { REST } = require('../../env')
//const base = `http://localhost:3003/widgets/`;
const base = `${REST}widgets`
const isObj = obj => {
    const check = (obj && Object.keys(obj).length) ? true : false;
    //console.log(obj, check);
    return check;
};
// const strValuesFromObj = obj => {
//     //const keys = Object.keys(obj);
//     let strvalues = '';
//     for (let key in obj) {
//         if (key === 'required') {
//             continue;
//         } else {
//             strvalues += `${key}=${obj[key]}&`;
//         }
//     }
//     //console.log(strvalues)
//     return strvalues;
// }

const Widget = (props) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    //const [error, setError] = useState(null);
    const { endpoint, params=null, counts, count, entryId } = props;
    const [newParams, setNewParams] = useState(params);
    const [date, setDate] = useState(newParams ? newParams.filters.period : '');
    const [newEntryId, setNewEntryId] = useState(entryId);
    //const [entry, setEntry] = useState([]);
    //const [loadingEntry, setLoadingEntry] = useState(true);
    const [countPoint, setCountPoint] = useState(count);

    useEffect(() => {
        const strparams = isObj(newParams) ? qs.stringify(newParams) : '';
        const url = strparams ? `${base}${endpoint}?${strparams}` : `${base}${endpoint}`;
        getData(url);
        // if (loadingEntry && selects && typeof(selects) === 'string') {
        //     const url = `http://localhost:3003/${selects}`;
        //     getEntry(url);
        // }
    }, [])

    useEffect(() => {
        if (date) {
            const par = {
                ...newParams,
                filters: {
                    ...newParams.filters,
                    period: date
                }
            };
            setNewParams(par);
            const strparams = isObj(par) ? qs.stringify(par) : '';
            const url = strparams ? `${base}${endpoint}?${strparams}` : `${base}${endpoint}`;
            getData(url);
        }
    }, [date]);

    useEffect(() => {
        if (countPoint) {
            const par = {
                ...newParams,
                filters: {
                    ...newParams.filters,
                    count: countPoint
                }
            };
            setNewParams(par);
            const strparams = isObj(par) ? qs.stringify(par) : '';
            const url = strparams ? `${base}${endpoint}?${strparams}` : `${base}${endpoint}`;
            getData(url);
        }
    }, [countPoint]);

    // useEffect(() => {
    //
    // }, [loadingEntry])

    useEffect(() => {
        //if (entryId) {
            if (newParams) {
                const par = {
                    ...newParams,
                    filters: {
                        ...newParams.filters,
                        //entry: {[`${selects}`]: entryId}
                        entryId: newEntryId
                    }
                };
                setNewParams(par);
                const strparams = isObj(par) ? qs.stringify(par) : '';
                const url = strparams ? `${base}${endpoint}?${strparams}` : `${base}${endpoint}`;
                getData(url);
            }
        // } else {
        //     const strparams = isObj(newParams) ? qs.stringify(newParams) : '';
        //     const url = strparams ? `${base}${endpoint}?${strparams}` : `${base}${endpoint}`;
        //     getData(url);
        // }
    }, [newEntryId])

    const getData = (url) => {
        //const strparams = isObj(params) ? qs.stringify(params) : '';
        //const url = strparams ? `${base}${endpoint}?${strparams}` : `${base}${endpoint}`;
        setLoading(true);
        axios(url)
            .then(response => {
                //console.log('widget', response);
                setData(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    // const getEntry = (url) => {
    //     axios(url)
    //         .then(response => {
    //             setEntry([{_id: 'all', name: 'По всем лигам'}, ...response.data]);
    //             setLoadingEntry(false);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }

    const onHandle = (evt) => {
        //console.log(evt); position: 'absolute', top: 0, left: 0, margin: '50% auto' display: 'flex', alignItems: 'center', justifyContent: 'center'
        setDate(evt.target.value);
    }
    const onChangeEntryId = (evt) => {
        if (evt.target.value === 'all') {
            setNewEntryId(null);
        } else {
            setNewEntryId(evt.target.value);
        }
    }
    const onChangeCount = evt => {
        setCountPoint(evt.target.value);
    }
    const Specified = types[props.type] ? types[props.type] : null;

    if (loading ) {
        return <Card className={`_${props.type}`} style={{position: 'relative', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{ margin: 'auto', position: 'absolute'}}><Loader style={{ margin: 'auto'}}/></div>
                </Card>
    } else {
        return <Card className={`_${props.type}`} >
                    <Specified {...props} data={data} date={date} count={countPoint} onHandle={onHandle} onChangeEntryId={onChangeEntryId} onChangeCount ={onChangeCount} entryId={newEntryId} counts={counts}/>
                </Card>
    }

}

export default Widget;
