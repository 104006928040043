import React, { useState } from 'react'
import { EntityButton } from '@common'
import { ListGroup, ListGroupItem, ListGroupItemHeading } from '@ui'

const AgegroupsBlock = ({ value, data, patch }) => {
    const pushGroup = f => {
        const upd = value ? value.concat(f) : [f]
        patch(upd)
    }

    return  <div>
                {!value || !value.length ? (
                    <div style={{textAlign: 'center'}}>
                        <span>не добавлено возрастных групп</span>
                    </div>
                ) : (
                    <ListGroup small>
                        {value.map(g => (
                            <ListGroupItem>{g.year}</ListGroupItem>
                        ))}
                    </ListGroup>
                )}

                <EntityButton
                    init={{
                        year: {type: 'text', value: '', placeholder: 'Год группы'},
                        female: {type: 'checkbox', value: false, label: 'женск'}
                    }}
                    onSubmit={f => pushGroup(Object.keys(f).reduce((acc, k) => {
                        acc[k] = f[k].value
                        return acc
                    }, {photoPresets: []}))}
                    cta='Добавить возрастную группу'
                />
            </div>
}

export default AgegroupsBlock
