import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@ui'
import rest from '@rest'

const defaultConfig = {
    template: 'plastic',
    photoPresets: [],
    contentTypes: ['matchDay', 'matchPost']
}

const SuggestionItem = ({ data, entity }) => {
    let [progress, setProgress] = useState(false)
    let body, actions
    const history = useHistory()

    const attachBotConfigToClub = async () => {
        rest('put', 'clubs', data._id, {
            botConfig: defaultConfig
        }).then(upd => {
            if(upd._id) {
                history.push(`/clubs/${data._id}`)
            }
        })
    }

    const createClub = async withConf => {
        rest('post', 'clubs', null, {
            name: data.name,
            botConfig: withConf ? defaultConfig : null
        }).then(created => {
            if(created._id) {
                if(withConf) {
                    history.push(`/clubs/${created._id}`)
                } else {
                    alert('Пустой клуб создан')
                }
            }
        })
    }

    switch (entity) {
        case 'clubs':
            body = (
                <div className='navbar-suggestions_item-body'>
                    <div className="image">
                        <img src={data.emblem } />
                    </div>
                    <span>{!data._id ? 'Создать новый клуб: ' : ''}{data.name}</span>
                    {data.from && data.from.city ? (
                        <span className='text-muted'>{data.from.city}</span>
                    ) : null}
                </div>
            )
            actions = (
                <div className='navbar-suggestions_item-actions'>
                    {!data._id ? [
                        <Button outline onClick={() => createClub(true)}>Клуб + клиентский конфиг</Button>,
                        <Button outline theme='light' style={{marginLeft: 14}} onClick={() => createClub()}>Только клуб</Button>
                    ] : !data.botConfig ? (
                        <Button outline onClick={() => attachBotConfigToClub()}>Сделать клиентом</Button>
                    ) : (
                        <Button theme='success' outline onClick={() => history.push(`/clubs/${data._id}`)}>Панель управления</Button>
                    )}
                </div>
            )
            break
        default:

    }

    return  <div className='navbar-suggestions_item'>
                {body}
                {actions}
            </div>
}

export default SuggestionItem
