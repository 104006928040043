const remoteSources = [
    {value: 'joinsport', label: 'JoinSport'},
    {value: 'goalstream', label: 'Голстрим'},
    {value: 'lflworld', label: 'Франшиза ЛФЛ'},
    {value: 'lfl', label: 'Сайт ЛФЛ'},
    {value: 'mosff', label: 'МОСФФ'},
    {value: 'olesports', label: 'OLE Promo'}
]

export { remoteSources }
