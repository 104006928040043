import React, { useState } from 'react'
// import Block from '@components/block'
// import blocks from './blocks'
import { Form, FormInput, InputGroup,InputGroupAddon, InputGroupText, Button, FormCheckbox, DatePicker } from "shards-react";
import { ProgressBar } from '@common'
import rest from '@rest'
import utils from '../../utils/utils';
const moment = require ('moment');
moment.locale('ru');
//console.log(utils);
//const base = 'https://amateum.fra1.cdn.digitaloceanspaces.com/presets/'

const ClubItem = ({ data, updList }) => {
    //console.log(data);
    const [player, setPlayer] = useState(data);
    const [name, setName] = useState(player.name || '');
    const [city, setCity] = useState(player.city || '');
    const [number, setNumber] = useState(player.number || '');
    const [datebd, setDate] = useState(player.birthday ? moment(player.birthday,'DD.MM.YYYY').format('YYYY-MM-DD') : null)
    const [avatar, setAvatar] = useState(player.avatarUrl ? player.avatarUrl : player.image ? player.image : '');
    const [newAvatar, setNewAvatar] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [warn, setWarn] = useState(player.moderatedwarn || false)
    const [saving, setSaving] = useState(false);

    const setData = ({target}) => {
        switch (target.name) {
            case 'name':
                setName(target.value)
                break;
            case 'city':
                setCity(target.value)
                break;
            case 'number':
                setNumber(target.value)
                break;
            case 'birthday':
                //console.log(target.value);
                setDate(target.value)
                break;
            default:
                break;
        }
    }

    const updatePlayer = (danger=false) => {
        const date = moment().format('YY-MM-DD');
        const updplayer = {...player, name: name, moderated: date};
        const form = {name: name, moderated: date};

        if (danger) {
            //console.log('danger',updplayer);
            setWarn(!warn)
        } else {
            if (newAvatar) {
                updplayer.avatarUrl = newAvatar;
                form.avatarUrl = newAvatar;
                setAvatar(newAvatar);
                setNewAvatar(null);
            }
            updplayer.moderatedwarn = warn;
            updplayer.city = city ? city : null;
            updplayer.number = number ? number : null;
            updplayer.birthday = datebd ? moment(datebd,'YYYY-MM-DD').format('DD.MM.YYYY') : null; //moment(datebd,'YY-MM-DD').format('DD.MM.YYYY')
            form.moderatedwarn = warn;
            form.city = updplayer.city;
            form.number = updplayer.number;
            form.birthday = updplayer.birthday; //moment(datebd,'YY-MM-DD').format('DD.MM.YYYY')

            //console.log('upd',updplayer,'form',form);
            setSaving(true)
            rest('put', 'players', updplayer._id, form)
                .then(upd => {
                    //console.log('upd rest pl',upd)
                    setPlayer(upd);
                    setSaving(false);
                    updList(upd, true)
                    //setInit({...form})
                })
                .catch(e => console.log(e))
            setPlayer(updplayer);
            updList(updplayer, true)
        }
    }
    const changeEmblem = async (action, url) => {
        //_.uploadFromRemoteUrl = async (url, benz=true, folder='presets', save=false ) => {
        let imgUrl;
        setUploading(true)
        switch (action) {
            case 'delAndCute':
                imgUrl = await utils.uplImgToDO({target: 'presets', benzin: true, defImgUrl: url});
                //console.log('changeEmblem', url, 'new', imgUrl);
                setNewAvatar(imgUrl);

                /*rest('put', 'uploader', null, {target: 'presets', benzin: true, defImgUrl: url})
                    .then(upl => {
                        //setUploading(false)
                        console.log(upl);
                        //patch([`${base}${filename}.${ext}`].concat(value))
                        //url = `${base}${filename}.${ext}`;
                        imgUrl = upl.url
                        console.log(imgUrl);
                        setNewEmblem(imgUrl);
                    })
                    .catch(e => console.log(e));*/
                break;
            case 'cute':
                imgUrl = await utils.uplImgToDO({target: 'presets', benzin: true, defImgUrl: url, trim: true});
                setNewAvatar(imgUrl);
                break;
            // case 'temlate':
            //     imgUrl = `${utils.base}presets/34a9458c-831a-4371-a55b-86ec25b04f46.png`;
            //     setNewAvatar(imgUrl);
            //     break;
            default:
                break;
        }
        setUploading(false)
        //return imgUrl;
    }

    const handleAttach = async e => {
        setUploading(true)
        const reader = new FileReader()
        let url;
        reader.onload = async () => {
            const filename = utils.uuidv4();
            const ext = e.target.files[0].type.includes('png') ? 'png' : 'jpg';
            url = await utils.uplImgToDO({target: 'players', filename: filename, ext: ext, data: reader.result, benzin: true})
            //console.log(url);
            setNewAvatar(url);
            /*rest('put', 'uploader', null, {target: 'presets', filename: filename, ext: ext, data: reader.result, benzin: true})
                .then(upl => {
                    //setUploading(false)
                    console.log(upl);
                    //patch([`${base}${filename}.${ext}`].concat(value))
                    //url = `${base}${filename}.${ext}`;
                    url = upl.url
                    console.log(url);
                    setNewEmblem(url);
                })
                .catch(e => console.log(e))
                .finally(e => setUploading(false))*/
            setUploading(false);
        }
        //console.log('handleAttach', e.target.files[0]);
        //console.log('handleAttach', reader.readAsDataURL(e.target.files[0]));
        reader.readAsDataURL(e.target.files[0])
        //return url ? url : '';
    }

    return  (
        <>
        <h5>Подготовка игрока</h5>
        <Form>
            <InputGroup>
                <InputGroupAddon type="prepend">
                    <InputGroupText>Имя</InputGroupText>
                </InputGroupAddon>
                <FormInput
                    name='name'
                    placeholder="name"
                    onChange={setData}
                    value={name}
                    autoComplete='off'
                />
            </InputGroup>
            <InputGroup style={{marginTop: 8}}>
                <InputGroupAddon type="prepend">
                    <InputGroupText>ДР</InputGroupText>
                </InputGroupAddon>
                <FormInput
                  //size="sm"
                  name='birthday'
                  type='date'
                  value={datebd}
                  //selected={this.state.startDate}
                  onChange={setData}
                  placeholderText="birthday"
                  //dropdownMode="select"
                  //className="text-center"
                />
                <InputGroupAddon type="prepend">
                    <InputGroupText>Номер</InputGroupText>
                </InputGroupAddon>
                <FormInput
                    name='number'
                    placeholder="number"
                    onChange={setData}
                    value={number}
                    autoComplete='off'
                />
            </InputGroup>
            <InputGroup style={{marginTop: 8}}>
                <InputGroupAddon type="prepend">
                    <InputGroupText>Город</InputGroupText>
                </InputGroupAddon>
                <FormInput
                    name='city'
                    placeholder="city"
                    onChange={setData}
                    value={city}
                    autoComplete='off'
                />
            </InputGroup>
            {avatar ? <div style={{fontSize: '10px'}}>fullurl from database: {avatar}</div> : null}
        </Form>
        <div className='col-controls' style={{display: 'flex', justifyContent: 'space-around', flexDirection:'column', alignItems: 'center'}}>
            {avatar ? <div className='emblems' style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                {newAvatar ? <img style={{ height: '150px'}} src={newAvatar} alt='newAvatar'/> : <><img style={{height:'100px'}} src={avatar} alt='emblem'/>
                <img style={{ height: '150px'}} src={avatar} alt='emblem'/></>}
            </div> : <div>player without avatar</div>}
            <div className='actions' style={{display: 'flex', justifyContent: 'space-between'}}>
                {uploading ? <ProgressBar label='обработка, ничего не нажимай =)' /> : null}
                <Button theme='info' disabled={uploading || !avatar} outline onClick={() => changeEmblem('delAndCute', avatar)}>Удалить фон и обрезать альфа-канал</Button>
                <Button disabled={uploading} className='references-upl-btn' outline>
                    <i className='material-icons'>{uploading ? 'hourglass_bottom' : 'add_circle_outline'}</i>
                    <input type='file' onChange={handleAttach} />
                    Загрузить с диска
                </Button>
                {/*<Button theme='primary' disabled={uploading || !avatar} outline  onClick={() => changeEmblem('cute', avatar )}>Обрезать альфа-канал</Button>*/}
                {/*<Button theme='secondary' disabled={uploading} outline onClick={() => changeEmblem('temlate')}>Установить заглушку</Button>*/}
                {/*<Button theme='light'><input type='file' onChange={() => changeEmblem('upload')} />Загрузить</Button>*/}
                {/*<Button theme='light' onClick={() => changeEmblem('upload')}><input type='file' onChange={() => changeEmblem('upload')} />Загрузить</Button>*/}
                {/*<Button theme='danger' disabled={uploading} outline onClick={() => updateClub(true)}>{warn ? 'Вмешательство больше не трубется' : 'Требует вмешательства'}</Button>*/}
                <FormCheckbox
                    toggle
                    checked={warn}
                    onChange={() => updatePlayer(true)}
                    //style={{display: 'flex', justifyContent: 'space-around', flexDirection:'column', alignItems: 'center'}}
                >Требует ручного вмешательства</FormCheckbox>
            </div>
            <Button theme='success' disabled={uploading || saving} outline onClick={() => updatePlayer()}>Обновить данные</Button>
        </div>
        </>
    )
}

export default ClubItem
