import React, { useState, useEffect } from 'react';
import {  Row,
    Card,
    Popover,
    PopoverBody,
 Col,
 Form,
 FormInput,
 FormCheckbox,
 FormGroup,
 InputGroup,
 InputGroupAddon,
 InputGroupText,
 Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";
const rest = require('../../rest');

/*tid: { type: Number, default: null },
first_name: { type: String, default: null },
last_name: { type: String, default: null },
roles: [{
    leagueId: { type: mongoose.Schema.ObjectId, default: null },
    clubId: { type: mongoose.Schema.ObjectId, default: null },
    role: { type: 'String' }
}],
invite: { type: Number, default: null },
globalAdmin: { type: Boolean, default: false },
email: { type: String, default: null },
activeSubject: { type: mongoose.Schema.ObjectId },
activeMode: { type: String, default: null },
activeMsg: { type: Number, default: null },
inputMode: { type: String, default: null },
broadcastMessage: { type: Number, default: null },
broadcastDefaults: { type: mongoose.Schema.Types.Mixed, default: {} }*/
//                club={userRole.hasOwnProperty('clubId') ? true : false}

    // const types = [
    //     ['sheets', 'Бланки протоколов', false],
    //     ['parseMatches', 'Выгрузка матчей', false],
    //     ['graphics', 'Графика', false],
    //     ['broadcast', 'Бродкаст', false],
    //     ['settings', 'Настройки', false],
    //     ['report', 'Ошибка в матче', false]
    // ];

    const types = {
        sheets: 'Бланки протоколов',
        parseMatches: 'Выгрузка матчей',
        graphics: 'Графика',
        broadcast: 'Бродкаст',
        settings: 'Настройки',
        report: 'Ошибка в матче'
    }

const AddRoleForm = ({role, botUser, toBotUsers, toBotUser, roleClub, roleLeague, clubRole=false}) => {
    const [userRole, setUserRole] = useState(null);
    const [newRole, setNewRole] = useState(null);
    const [user, setUser] = useState(botUser);

    const [userRoles, setUserRoles] = useState(user.roles ? user.roles : []);

    const [clubs, setClubs] = useState([]);
    const [club, setClub] = useState(null);
    const [clubwis, setClubwis] = useState(null);
    const [term, setTerm] = useState('');
    const [season, setSeason] = useState(null);
    const [seasons, setSeasons] = useState([]);
    const [league, setLeague] = useState(null);
    const [leagues, setLeagues] = useState([]);
    const [leagueTypes, setLeagueTypes] = useState(types);
    const [checkedTypes, setCheckedTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setUserRole(role);
        setNewRole(role);
        setCheckedTypes(role.keyboard);
        if (clubRole) {
            setLeague(null);
        } else {
            setLeague (role ? (role.league ? role.league : null) : null);
        }
    }, []);

    useEffect(() => {
        if (clubRole) {
            if (term.length > 2 && !club) {
                setLoading(true);
                const userclubs = userRoles.filter(item => item.clubId).map(item => item.clubId);
                rest('get', 'clubs')
                    .then(clubs => {
                        const termclubs = clubs.filter(item => item.name.toLowerCase().includes(term.toLowerCase()));
                        const newClubs = termclubs.filter(item => !userclubs.includes(item._id));
                        setClubs(newClubs);
                        setLoading(false);
                    });
            } else {setClubs([])}
        } else {
            if (term.length > 2 && !league) {
                setLoading(true);
                const userleagues = userRoles.filter(item => item.leagueId).map(item => item.leagueId);
                //rest('get', 'clubs'+(term.length > 0 ? `?query[name]=${term}` : ''))
                rest('get', 'leaguesonlys')
                    .then(leagues => {
                        const termleagues = leagues.filter(item => item.name.toLowerCase().includes(term.toLowerCase()));
                        const newleagues = termleagues.filter(item => !userleagues.includes(item._id));
                        setLeagues(newleagues);
                        setLoading(false);
                    });
            } else {
                setLeagues([])
            }
        }
    }, [term]);

    useEffect(() => {
        if (user && user.roles) {
            setUserRoles(user.roles);
        }
    },[user]);

    useEffect(() => {
        if (newRole) {
            if (clubRole) {
                setLeagueTypes([]);
            } else {
                setCheckedTypes(newRole.keyboard)
            }
        }
    },[newRole]);

    useEffect(() => {
        if (club) {
            rest('get','clubwis',club._id)
                .then(clubwis => {
                    setClubwis(clubwis);
                    if (clubwis) {
                        if (clubwis.seasons.length === 1 && clubwis.datasources.length === 0) {
                            //setSeasons([clubwis.seasons[0]]);
                            setSeason(clubwis.seasons[0]);
                            setNewRole({
                                ...newRole,
                                seasonId: clubwis.seasons[0]._id
                            });
                        } else if (clubwis.datasources.length === 1 && clubwis.seasons.length === 0) {
                            setSeason(clubwis.datasources[0]);
                            setNewRole({
                                ...newRole,
                                dataSourceId: clubwis.datasources[0]._id
                            });
                            //setSeasons([clubwis.datasources[0]]);
                        } else {
                            setSeasons([...clubwis.seasons, ...clubwis.datasources]);
                        }
                    } else {
                        setSeasons([]);
                    }
                })
                .catch(e => console.log(e))
        } else {
            setTerm('');
            setClubwis(null);
            setSeason(null);
            setSeasons([]);
        }
    }, [club]);

    useEffect(() => {
        if (league) {
            setNewRole({
                ...newRole,
                leagueId: league._id,
                league: league
            });
            setLeagueTypes(types);
            setTerm(league.name);
        } else {
            setLeagues([])
            setLeagueTypes(types)
        }
    }, [league]);

    const getData = (obj, collection='clubs') => {
        setTerm(obj.name);
        if (collection === 'clubs') {
            setClub(obj);
            setNewRole({
                ...newRole,
                clubId: obj._id
            });
        } else {
            setLeague(obj);
            // setNewRole({
            //     ...newRole,
            //     leagueId: obj._id
            // });
        }
    }

    const getSeason = season => {
        setSeason(season);
        if (season.hasOwnProperty('clubId')) {
            setNewRole({
                ...newRole,
                dataSourceId: season._id
            })
        } else {
            setNewRole({
                ...newRole,
                seasonId: season._id
            })
        }
    }
    const updUserRoles = async (id, roles) => {
        //rest('put', 'botusers', id, {'roles': [...roles]})
        if (user.activeSubject) {
            await rest('put', 'botusers', id, {'roles': roles})
                .then(upd => {
                    console.log('changeUser updUserRoles', upd);
                    if (upd && upd.roles) {
                        setUser(upd);
                        //setUserRoles(upd.roles);
                    }
                })
                .catch(e => console.log(e))
                .finally( console.log('updUserRoles',id, roles))
        } else {
            const activeSubject = roles[0] ? roles[0].clubId ? roles[0].clubId : roles[0].leagueId : null;
            await rest('put', 'botusers', id, {activeSubject: activeSubject, 'roles': roles})
                .then(upd => {
                    console.log('changeUser updUserRoles', upd);
                    if (upd && upd.roles) {
                        setUser(upd);
                        //setUserRoles(upd.roles);
                    }
                })
                .catch(e => console.log(e))
                .finally( console.log('updUserRoles',id, roles))
        }

    }
    const addRole = async (role) => {
        if (clubRole) {
            const ind = userRoles.findIndex(item => item.clubId === role.clubId);
            if (ind === -1) {
                setUserRoles([...userRoles, role]);
                await updUserRoles(user._id, [...userRoles, role]);
            } else {
                const newroles = [...userRoles.slice(0, ind), role, ...userRoles.slice(ind+1)];
                setUserRoles(newroles);
                await updUserRoles(user._id, newroles);
            }
            setUserRole(roleClub);
            setNewRole(roleClub);
            setClub(null);
        } else {
            const indx = userRoles.findIndex(item => item.leagueId === role.leagueId);
            if (indx === -1) {
                await updUserRoles(user._id, [...userRoles, {
                    ...role,
                    keyboard: checkedTypes
                }]);
                setUserRoles([...userRoles, {
                    ...role,
                    keyboard: checkedTypes
                }]);
            } else {
                const newroles = [...userRoles.slice(0, indx), role, ...userRoles.slice(indx+1)];
                setUserRoles(newroles);
                await updUserRoles(user._id, newroles);
            }
            setUserRole(roleLeague);
            setNewRole(roleLeague);
            setLeague(null);
            setLeagues([]);
            setCheckedTypes([]);
            setTerm('');
        }
    }

    const handleField = (evt) => {
        let key = evt.target.name;
        if (key === 'term') {
            setClub(null);
            setSeason(null);
            setSeasons([]);
            setClubs([]);
            setLeague(null);
            setLeagues([]);
            setTerm(evt.target.value);
        } else if (key === 'role') {
            setNewRole({
                ...newRole,
                role: evt.target.value
            });
        } else {
            if (checkedTypes.includes(key)) {
                const cheks = checkedTypes.filter(item => item !== key);
                setCheckedTypes(cheks);
                setNewRole({
                    ...newRole,
                    keyboard: cheks
                });
            } else {
                setCheckedTypes([...checkedTypes, key]);
                setNewRole({
                    ...newRole,
                    keyboard: [...checkedTypes, key]
                });
            }
            console.log('setNewRole',checkedTypes)
        }
    }

    return (
        userRole ?
                <Col sm="12" md="6">
                    <strong className="text-muted d-block mb-2">Role</strong>
                    <Form>
                        <FormGroup>
                          <InputGroup className="mb-3">
                            <FormInput
                                name='term'
                                placeholder= {userRole.hasOwnProperty('clubId') && !userRole.leagueId ? "Введите название клуба" : "Введите название лиги"}
                                value={term}
                                onChange={handleField}
                                autoComplete='off'
                                autoFocus
                            />
                          </InputGroup>
                          <InputGroup className="mb-3">
                            <FormInput
                              name='role'
                              placeholder="role"
                              value={newRole ? newRole.role : ''}
                              onChange={handleField}
                              autoComplete='off'
                            />
                          </InputGroup>
                        </FormGroup>
                        {userRole.hasOwnProperty('clubId') && !userRole.leagueId
                        ?
                        <>
                            <InputGroup className="mb-3" style={{display: season ? 'block' : 'none'}}>
                              <FormInput
                                name='season'
                                placeholder="role"
                                value={season ? season.name ? season.name : season.remoteMeta.name ? season.remoteMeta.name : `${season.remoteSource}. ${season.remoteId}` : ''}
                                onChange={handleField}
                                autoComplete='off'
                                style={{width: '100%'}}
                                disabled={true}
                              />
                            </InputGroup>
                            {(loading) ? <div>загрузка...</div>
                            :
                                <>
                                    <div  style={{display: (!loading && term.length > 2) ? 'block' : 'none'}}>{clubs.length ? "Выберите клуб" : club ? 'добавьте роль' : 'Клубы не найдены, или уже добавлены'}</div>

                                    <div style={{padding: 5, display: clubs.length ? 'block' : 'none', maxHeight:300, overflowY:'scroll', marginBottom: 20}}>
                                        {clubs.map(club =>
                                                <div
                                                    key={club._id}
                                                    onClick={() => getData(club)}
                                                    style={{padding: 5, cursor: 'pointer', border: '1px solid black', width:300, marginBottom: 10}}
                                                > <b>{club.name}</b> {club.from ? club.from.city : ''} </div>
                                        )}
                                    </div>
                                    <div style={{padding: 5, display: seasons.length ? 'block' : 'none', maxHeight:300, overflowY:'scroll', marginBottom: 20}}>
                                        {seasons.length ? "Выберите сезон" : null}
                                        {seasons.map(seas =>
                                                <div
                                                    key={seas._id}
                                                    onClick={() => getSeason(seas)}
                                                    style={{padding: 5, cursor: 'pointer', border: '1px solid black', width:300, marginBottom: 10}}
                                                > <b>{seas.name ? seas.name : seas.remoteMeta.name ? seas.remoteMeta.name : `${seas.remoteSource}. ${seas.remoteId}`}</b></div>
                                        )}
                                    </div>
                                </>
                            }
                        </>
                        :
                            <>
                                {(loading) ? <div>загрузка...</div>
                                :
                                    <>
                                        <div style={{display: (!loading && term.length > 2) ? 'block' : 'none'}}>{leagues.length ? "Выберите лигу" : 'Лиги не найдены, или уже добавлены'}</div>
                                        <div style={{padding: 5, display: leagues.length ? 'block' : 'none', maxHeight:300, overflowY:'scroll', marginBottom: 20}}>
                                            {leagues.map(league =>
                                                    <div
                                                        key={league._id}
                                                        onClick={() => getData(league, 'leagues')}
                                                        style={{padding: 5, cursor: 'pointer', border: '1px solid black', width:300, marginBottom: 10}}
                                                    > <b>{league.name}</b></div>
                                            )}
                                        </div>
                                        {(league && leagueTypes) ?
                                            <div style={{display: 'flex', flexDirection:'column', flexWrap:'wrap',  justifyContent:'space-around', width: '100%', marginTop: 10, marginBottom: 5}}>
                                                {Object.keys(leagueTypes).map(item => <FormCheckbox
                                                        key={item}
                                                        checked={checkedTypes.includes(item)}
                                                        onChange={handleField}
                                                        name={item}
                                                        style={{width: '45%'}}
                                                    >{leagueTypes[item]}</FormCheckbox >
                                                )}
                                            </div>
                                        : null
                                        }
                                    </>
                                }
                            </>
                        }
                    </Form>
                      <Button
                          pill
                          active
                          onClick = {() => addRole(newRole)}
                          theme="success"
                          disabled={((newRole.clubId && (newRole.seasonId || newRole.dataSourceId)) || newRole.leagueId) ? false : true}
                      >Add role</Button>
                      <Button
                          pill
                          active
                          onClick = {() => toBotUser(user._id)}
                          theme="secondary"
                      > to user </Button>
                      <Button
                          pill
                          active
                          onClick = {toBotUsers}
                          theme="primary"
                      > to list </Button>
                </Col>
        : null
    )
}

export default AddRoleForm;
