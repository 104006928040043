import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SuggestionItem from './SuggestionItem'
import rest from '@rest'

import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from '@ui'

const NavbarSearch = () => {
    const [query, setQuery] = useState(null)
    const [suggestions, setSuggestions] = useState([])

    const { itemId } = useParams()

    useEffect(() => {
        if(query && query.length > 2) {
            rest('get', 'clubs', `?suggest[name]=${query}`)
                .then(suggestions => {
                    setSuggestions(suggestions)
                })
        } else {
            setSuggestions([])
        }
    }, [query])

    useEffect(() => {
        setSuggestions([])
    }, [itemId])

    return  <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
                <InputGroup seamless className="ml-3">
                    <InputGroupAddon type="prepend">
                        <InputGroupText>
                            <i className="material-icons">search</i>
                        </InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                        onChange={e => setQuery(e.target.value)}
                        value={query}
                        className="navbar-search"
                        placeholder="Искать клуб..."
                    />
                </InputGroup>
                {query && query.length > 2 ? (
                    <div className='navbar-suggestions'>
                        {suggestions.map(sug => (
                            <SuggestionItem
                                entity='clubs'
                                data={sug}
                            />
                        ))}
                        <SuggestionItem
                            entity='clubs'
                            data={{name: query}}
                        />
                    </div>
                ) : null}
            </Form>
}

export default NavbarSearch
