import React, { useState } from 'react'
import { Progress } from '@ui'
import './style.css'

const ProgressBar = ({ value, label }) => {
    return  <div className='progress-wrap'>
                <span>{label}</span>
                <Progress
                    striped={true}
                    animated={true}
                    value={value || 100}
                />
            </div>
}

export default ProgressBar
