import React, { useState, useEffect } from 'react';
import { Container,
    Card,
    CardBody,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormGroup,
    FormInput,
    FormSelect,
    FormTextarea,
    Button,
    ButtonGroup
} from "shards-react";

const BotUser = ({user, delUser, changeUser}) => {
    return (
        user ?
        // <Container>
        <Row>
            <Col>
                <ListGroup flush style={{ width: "100%" }}>
                    <ListGroupItem className="d-flex px-3 border-0">
                        <span>{user.first_name} {user.last_name}</span>
                        <ButtonGroup size="sm">
                            <Button
                                pill
                                active
                                onClick = {delUser}
                                theme="danger"
                            ><i className="material-icons">delete</i> Delete </Button>
                            <Button
                                pill
                                active
                                onClick = {changeUser}
                                theme="secondary"
                            > <i className="material-icons">edit</i> Change </Button>
                        </ButtonGroup>
                    </ListGroupItem>
                </ListGroup>
            </Col>
        </Row>
        : null
        // </Container>
    )
}

export default BotUser;
